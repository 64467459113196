import React, { useEffect, useState } from "react";
import { Form, FormGroup, Label, Button, Input } from "reactstrap";
import Default from "../assets/img/default.png";

const StepThree = ({
  visitState,
  brandIndex,
  _onUpdate,
  nextStep,
  prevStep,
  updateStep,
  isViewModeOnly,
}) => {
  const [localState, setLocalState] = useState([
    {
      productName: "",
      productQuantity: "",
      productImage: ""
    },
  ]);

  const _handleLocalStateUpdate = (type, index = null) => {
    let updatedLocalState = [...localState];
    switch (type) {
      case "add":
        updatedLocalState?.push({ productName: "", productQuantity: "" });
        break;
      case "delete":
        updatedLocalState?.splice(index, 1);
        break;
      default:
        break;
    }
    setLocalState(updatedLocalState);
  };

  const _handleChange = (key, value, index) => {
    let updatedLocalState = localState?.length ? [...localState] : [];

    updatedLocalState[index][key] = value;
    setLocalState(updatedLocalState);

    let updatedVisitState = { ...visitState };
    visitState.brandsData[brandIndex]["stockInHand"] = updatedLocalState;
    _onUpdate("visit", updatedVisitState);
  };

  useEffect(() => {
    setLocalState(visitState?.brandsData[brandIndex]["stockInHand"]);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className="step-indicator">
        <div className="step" onClick={() => updateStep(1)}>
          Pre-Merchandised Image
        </div>
        <div className="step" onClick={() => updateStep(2)}>
          Post-Merchandised Image
        </div>
        <div className="step active">Stock In-hand</div>
        <div className="step" onClick={() => updateStep(4)}>
          New Order
        </div>
      </div>
      <Form style={{ maxWidth: 600 }} className="mx-auto">
        <div className="d-flex justify-content-between align-items-center">
          <h5 className="mb-0">Stock In-hand</h5>

          {!isViewModeOnly ? (
            <Button
              className="addBtn"
              onClick={() => _handleLocalStateUpdate("add")}
            >
              <i className="fas fa-plus me-1" />
              Add
            </Button>
          ) : null}
        </div>

        {localState?.length
          ? localState?.map((each, index) => (
            <div className="stockInHandWrap" key={index}>
              {/* if another sku gets added then only show this */}
              {index !== 0 || localState?.length > 1 ? (
                <Button
                  color="link"
                  onClick={() => _handleLocalStateUpdate("delete", index)}
                  disabled={isViewModeOnly}
                  hidden={isViewModeOnly}
                >
                  <i className="fas fa-times" />
                </Button>
              ) : null}

              <div>
                <FormGroup>
                  <Label>SKU Name</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={each?.productName}
                    onChange={(e) =>
                      _handleChange("productName", e.target.value, index)
                    }
                    disabled={isViewModeOnly}
                  />
                </FormGroup>
                <FormGroup>
                  <Label>Quantity</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={each?.productQuantity}
                    onChange={(e) =>
                      _handleChange("productQuantity", e.target.value, index)
                    }
                    disabled={isViewModeOnly}
                  />
                </FormGroup>
              </div>

              <div>
                <Label>Product Image</Label>
                <div className="productImg">
                  <img src={each?.productImage || Default} alt="Product" />
                </div>
              </div>
            </div>
          ))
          : <p className="mt-3 mb-5 text-center" style={{fontSize: 16}}>No SKU(s)</p>}

        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 30 }}
        >
          <Button className="prevBtn mx-2" onClick={prevStep}>
            Prev
          </Button>
          <Button className="addBtn mx-2" onClick={nextStep}>
            Next
          </Button>
        </div>
      </Form>
    </>
  );
};

export default StepThree;
