import React, { useState } from "react";
import StepOne from "./StepOne";
import StepTwo from "./StepTwo";
import StepThree from "./StepThree";
import StepFour from "./StepFour";

const MultiStepForm = ({
  visitState,
  brandIndex,
  _onUpdate,
  switchTab,
  isViewModeOnly = false,
  isEditMode = false
}) => {
  const [step, setStep] = useState(1);
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission
    console.log(formData);
  };

  switch (step) {
    case 1:
      return (
        <StepOne
          visitState={visitState}
          brandIndex={brandIndex}
          _onUpdate={_onUpdate}
          nextStep={nextStep}
          handleChange={handleChange}
          values={formData}
          updateStep={setStep}
          isViewModeOnly={isViewModeOnly}
        />
      );
    case 2:
      return (
        <StepTwo
          visitState={visitState}
          brandIndex={brandIndex}
          _onUpdate={_onUpdate}
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={formData}
          updateStep={setStep}
          isViewModeOnly={isViewModeOnly}
        />
      );
    case 3:
      return (
        <StepThree
          visitState={visitState}
          brandIndex={brandIndex}
          _onUpdate={_onUpdate}
          nextStep={nextStep}
          prevStep={prevStep}
          handleSubmit={handleSubmit}
          values={formData}
          updateStep={setStep}
          isViewModeOnly={isViewModeOnly}
        />
      );
    case 4:
      return (
        <StepFour
          visitState={visitState}
          brandIndex={brandIndex}
          _onUpdate={_onUpdate}
          prevStep={prevStep}
          handleSubmit={handleSubmit}
          values={formData}
          switchTab={switchTab}
          updateStep={setStep}
          isViewModeOnly={isViewModeOnly}
          isEditMode={isEditMode}
        />
      );
    default:
      return (
        <StepOne
          visitState={visitState}
          brandIndex={brandIndex}
          _onUpdate={_onUpdate}
          nextStep={nextStep}
          handleChange={handleChange}
          values={formData}
          updateStep={setStep}
          isViewModeOnly={isViewModeOnly}
        />
      );
  }
};

export default MultiStepForm;
