import React from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

import FullPageLoader from "./containers/FullPageLoader";
import ErrorNotFound from "./components/ErrorNotFound";
import ProtectedRoute from "./components/routes/ProtectedRoute";
import PublicRoute from "./components/routes/PublicRoute";
import DashboardLayout from "./containers/Dashboard/DashboardLayout";
import LoginPage from "./pages/public-pages/LoginPage";
import ForgotPasswordPage from "./pages/public-pages/ForgotPasswordPage";

const App = () => {
  return (
    <Router>
      <>
        <FullPageLoader />
        <ToastContainer
          position="top-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        <Routes>
          <Route path="" element={<PublicRoute redirectRoute={"/shop"} />}>
            <Route exact path="/login" element={<LoginPage />} />
            <Route
              exact
              path="/forgot-password"
              element={<ForgotPasswordPage />}
            />

            <Route index element={<Navigate replace to="/login" />} />
          </Route>

          <Route path="" element={<ProtectedRoute redirectRoute={"/login"} />}>
            <Route path="/*" element={<DashboardLayout />} />
          </Route>

          <Route path="*" element={<Navigate replace to="/" />} />

          <Route element={<ErrorNotFound />} />
        </Routes>
      </>
    </Router>
  );
};

export default App;
