import React from "react";

const PublicFooter = () => {
  return (
    <>
      <div className="projectFooter">
        © 2024 Nxt Door

        <div className="copyright">
          Powered By
          <a href="#" target="_blank" rel="noreferrer" className="ms-1">
            ASV
          </a>
        </div>
      </div>
    </>
  );
};

export default PublicFooter;
