import {
  ADD_SETTINGS_DATA,
  UPDATE_SETTINGS_DATA,
  CLEAR_SETTINGS_DATA,
} from "./action-types";

export const addSettingsData = (data) => {
  return {
    type: ADD_SETTINGS_DATA,
    payload: {
      data,
    },
  };
};

export const updateSettingsData = (data) => {
  return {
    type: UPDATE_SETTINGS_DATA,
    payload: data,
  };
};

export const clearSettingsData = () => {
  return {
    type: CLEAR_SETTINGS_DATA,
  };
};
