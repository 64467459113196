import {
  makeDeleteRequest,
  makeGetRequest,
  makePostRequest,
  makePutRequest,
  uploadCSVFile,
  // makePutRequest,
  // uploadFile,
  // makeDeleteRequest,
  // uploadFileMultiPart
} from "./http-service";
import { BASE_URL } from "../config/index";

export const login = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/login`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const forgotPassword = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/forgotpassword`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchShops = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/shops`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchShopById = (id) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/shop/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createShop = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/shop`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateShop = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/shop/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const removeShop = (id) => {
  return new Promise((resolve, reject) => {
    makeDeleteRequest(`${BASE_URL}/shop/${id}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const assignShopToMerchendiser = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/assign/shop/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchBrands = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/fetch/brand/shops`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const massEmailToBrands = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/send/brand/emails`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const handleApproveRejectBid = (bidId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/bid/${bidId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const handlePlaceBid = (bidId, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add/bid/${bidId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const requestInvitation = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchLoggedInDashboardData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/dashboard`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchBrandDashboardData = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/dashboard/brand`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// Merchendizer start
export const fetchAllMerchendiser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/merchandisers`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createNewMerchendiser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/merchandiser`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateMerchendiserById = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/merchandiser/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchAllShopsForMerchendiser = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/fetch/merchandiser/shops`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
// Merchendizer end

/**
 * @param {string} platform - google or facebook
 * @param {object} payload - {accessToken: google or facefook response token}
 * @returns
 */
export const socialLogin = (platform, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/${platform}/signup`, false, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

/**
 * used for check availability of phone or email or username
 *
 * @param {string} payload - phone or email or username
 * @returns
 */
export const checkAvailability = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/unique`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const getLoggedInUserDetail = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/user`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

// api to place bids
export const addBids = (payload, id) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/add/bid/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchAllBrands = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/brands`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const createBrand = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/brand`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateBrand = (id, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/brand/${id}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

//settings api start
export const fetchSettings = () => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/setting`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateSettings = (payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/setting`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
//settings api end

//visit api start
export const createVisitForShop = (shopId, payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/visit/${shopId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const updateVisit = (visitId, payload) => {
  return new Promise((resolve, reject) => {
    makePutRequest(`${BASE_URL}/visit/${visitId}`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const fetchVisitDetailsById = (visitId) => {
  return new Promise((resolve, reject) => {
    makeGetRequest(`${BASE_URL}/visit/${visitId}`, true)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};
//visit api end

export const fetchMerchandiserShops = (payload) => {
  return new Promise((resolve, reject) => {
    makePostRequest(`${BASE_URL}/fetch/merchandiser/shops`, true, payload)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error>>", e);
        reject(e);
      });
  });
};

export const generateReportCSV = (data) => {
  return new Promise((resolve, reject) => {
    uploadCSVFile(`${BASE_URL}/generate/report`, true, data)
      .then((res) => {
        resolve(res);
      })
      .catch((e) => {
        console.log("API call error: ", e);
        reject(e);
      });
  });
};
