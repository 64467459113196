import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Col,
  Label,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Row,
  FormGroup,
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import MultiStepForm from "../../components/MutiStepForm";
import { showToaster } from "../../helper-methods";
import { fetchVisitDetailsById, updateVisit } from "../../http/http-calls";
import Default from "../../assets/img/default.png";

const ViewVisitPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [activeTab, setActiveTab] = useState(1);
  const [visitData, setVisitData] = useState(null);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const _fetchVisitData = async (visitId) => {
    try {
      const res = await fetchVisitDetailsById(visitId);
      setVisitData(res?.visit);
    } catch (e) {
      showToaster("error", e.reason);
    }
  };

  const _updateSelfieStatus = async (key, value) => {
    try {
      const payload = {}
      payload[key] = value
      await updateVisit(params?.visitId, payload);
      _fetchVisitData(params?.visitId);
    } catch (e) {
      showToaster("error", e.reason);
    }
  }

  const _updateBrandStatus = async (brandId, value) => {
    try {
      const payload = {
        brandObj: {
          brandId
        }
      }
      payload.brandObj["status"] = value
      await updateVisit(params?.visitId, payload);
      _fetchVisitData(params?.visitId);
    } catch (e) {
      showToaster("error", e.reason);
    }
  }

  useEffect(() => {
    if (params?.visitId && visitData === null) _fetchVisitData(params?.visitId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // console.log("test", visitData);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle justify-content-start">
          <Button className="backBtn" onClick={() => navigate(-1)} outline>
            <i className="fas fa-chevron-left" />
          </Button>
          <h2>Visit</h2>
        </div>
      </Col>
      <Col sm={12}>
        <Row className="visitStepWrap">
          <Col md={6} className="d-flex flex-column justify-content-center">
            <FormGroup className="mb-4">
              <Label>Visit Name</Label>
              <Input value={visitData?.name} disabled={true} />
            </FormGroup>
          </Col>
          <Col md={6} className="text-center">
            <Label>Attendance Selfie</Label>
            <div className="uploadedSelfie">
              <img src={visitData?.selfieImage || Default} alt="Selfie" />
            </div>
          </Col>

          <div className="mt-5 mb-2 d-flex justify-content-center">
            <hr style={{ borderTopStyle: 'dashed' }} />
            {visitData?.selfieAttendanceStatus === "Added" ? (
              <div>
                <Button
                  onClick={() => _updateSelfieStatus("selfieAttendanceStatus", "Approved")}
                  color="success" outline className="me-3" style={{ padding: 12 }}>
                  Approve
                </Button>

                <Button
                  onClick={() => _updateSelfieStatus("selfieAttendanceStatus", "Declined")}
                  color="danger" outline style={{ padding: 12 }}>
                  Reject
                </Button>
              </div>
            ) : (
              <span
                className={
                  visitData?.selfieAttendanceStatus === "Approved"
                    ? "statusApproved"
                    : "statusDeclined"
                }
              >
                {visitData?.selfieAttendanceStatus}
              </span>
            )}

          </div>
        </Row>
      </Col>
      {/* step 1. After it gets completed, show step 2 */}

      {/* step 2, show this only after step 1 gets completed */}
      <Col sm={12}>
        <div className="visitStepWrap px-3">
          <Nav pills className="customTabs">
            {visitData !== null && visitData?.brandsData?.length
              ? visitData?.brandsData?.map((each, index) => (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === index + 1,
                    })}
                    onClick={() => {
                      toggle(index + 1);
                    }}
                  >
                    {each?._brand?.name?.full}
                  </NavLink>
                </NavItem>
              ))
              : null}
          </Nav>

          <TabContent activeTab={activeTab} className="customTabContent mb-0">
            {visitData !== null && visitData?.brandsData?.length
              ? visitData?.brandsData?.map((each, index) => (
                <TabPane tabId={index + 1} key={index}>
                  <MultiStepForm
                    isViewModeOnly={true}
                    visitState={visitData}
                    brandIndex={index}
                    switchTab={toggle}
                  />

                  <div className="mt-5 mb-2 d-flex justify-content-center" key={index}>
                    <hr style={{ borderTopStyle: 'dashed' }} />
                    {each?.status === "Added" ? (
                      <div>
                        <Button
                          onClick={() => _updateBrandStatus(each?._brand?._id, "Approved")}
                          color="success" outline className="me-3" style={{ padding: 12 }}>
                          Approve
                        </Button>

                        <Button
                          onClick={() => _updateBrandStatus(each?._brand?._id, "Declined")}
                          color="danger" outline style={{ padding: 12 }}>
                          Reject
                        </Button>
                      </div>
                    ) : (
                      <span
                        className={
                          each?.status === "Approved"
                            ? "statusApproved"
                            : "statusDeclined"
                        }
                      >
                        {each?.status}
                      </span>
                    )}
                  </div>
                </TabPane>
              ))
              : null}
          </TabContent>
        </div>
      </Col>
    </>
  );
};

export default ViewVisitPage;
