import React, { useState } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
} from "reactstrap";
import classnames from "classnames";
import DynamicTable from "./DynamicTable";

const BrandDashboard = ({ renderData }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <div>
      <Nav pills className="customTabs">
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "1" })}
            onClick={() => {
              toggle("1");
            }}
          >
            Owned Shops
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "2" })}
            onClick={() => {
              toggle("2");
            }}
          >
            Requested Shelves
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({ active: activeTab === "3" })}
            onClick={() => {
              toggle("3");
            }}
          >
            Rejected Bids
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeTab} className="customTabContent">
        <TabPane tabId="1">
          <DynamicTable type="owned" data={renderData?.ownedShops} />
        </TabPane>
        <TabPane tabId="2">
          <DynamicTable type="requested" data={renderData?.pendingBids} />
        </TabPane>
        <TabPane tabId="3">
          <DynamicTable type="rejected" data={renderData?.rejectedBids} />
        </TabPane>
      </TabContent>
    </div>
  );
};

export default BrandDashboard;
