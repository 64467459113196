import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import {
  Navbar,
  NavbarBrand,
  Button,
  NavItem,
  Nav,
  NavbarToggler,
  Collapse,
  NavLink,
} from "reactstrap";
import { logout } from "../../helper-methods";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

const DashboardHeader = ({ isShow, setIsShow }) => {
  const loginToken = useSelector((state) => state.userCredential);
  const userData = jwtDecode(loginToken?.token);
  const navigate = useNavigate();
  const location = useLocation();
  const _logout = () => {
    logout(navigate);
  };

  // menu
  const _isActiveTab = (route) => {
    return location?.pathname === route ? true : false;
  };

  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => setIsOpen(!isOpen);

  return (
    <Navbar expand="md" className="projectHeader fixed-top">
      <NavbarBrand onClick={() => navigate("/")}>
        <img
          src={require("../../assets/img/company-logo-ndr.jpg")}
          alt="Logo"
        />
      </NavbarBrand>
      <NavbarToggler onClick={toggle} />
      <Collapse isOpen={isOpen} navbar>
        {/* className="m-auto order-md-2 d-md-flex d-none"  */}
        <Nav navbar>
          {userData?.role === "admin" ? (
            <>
              <NavItem>
                <NavLink
                  href="/shop-dashboard"
                  className={_isActiveTab("/shop-dashboard") ? "active" : ""}
                  onClick={() => navigate("/shop-dashboard")}
                >
                  Dashboard
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/shop"
                  className={_isActiveTab("/shop") ? "active" : ""}
                  onClick={() => navigate("/shop")}
                >
                  Shop
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/brands"
                  className={_isActiveTab("/brands") ? "active" : ""}
                  onClick={() => navigate("/brands")}
                >
                  Brand
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/sales"
                  className={_isActiveTab("/sales") ? "active" : ""}
                  onClick={() => navigate("/sales")}
                >
                  Merchandiser
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  href="/settings"
                  className={_isActiveTab("/settings") ? "active" : ""}
                  onClick={() => navigate("/settings")}
                >
                  Settings
                </NavLink>
              </NavItem>
            </>
          ) : userData?.role === "Brand" ? (
            <>
              {userData?.isShowDashboard ? (
                <NavItem>
                  <NavLink
                    href="/brand-dashboard"
                    className={_isActiveTab("/brand-dashboard") ? "active" : ""}
                    onClick={() => navigate("/brand-dashboard")}
                  >
                    Dashboard
                  </NavLink>
                </NavItem>
              ) : null}

              <NavItem>
                <NavLink
                  href="/brand"
                  className={_isActiveTab("/brand") ? "active" : ""}
                  onClick={() => navigate("/brand")}
                >
                  Listing
                </NavLink>
              </NavItem>
            </>
          ) : (
            <>
              <NavItem>
                <NavLink
                  href="/merachandiser-shop"
                  className={
                    _isActiveTab("/merachandiser-shop") ? "active" : ""
                  }
                  onClick={() => navigate("/merachandiser-shop")}
                >
                  Listing
                </NavLink>
              </NavItem>
            </>
          )}
        </Nav>

        <Button color="link" className="logoutBtn" onClick={() => _logout()}>
          <i className="fas fa-power-off" />
          <span>Logout</span>
        </Button>
      </Collapse>
    </Navbar>
  );
};

export default DashboardHeader;
