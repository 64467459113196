import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Input,
  InputGroup,
  Spinner,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
  FormGroup,
  UncontrolledTooltip,
  Label,
} from "reactstrap";
import { fetchAllMerchendiser } from "../../http/http-calls";
import PaginatedItems from "../../components/PaginatedItems";
import { capitalize, copyToClipBoard, deepClone } from "../../helper-methods";
import SendEmailModal from "../../components/modals/SendEmailModal";
import moment from "moment";
import AddEditSalesPerson from "../../components/modals/AddEditSalesPerson";

const SalesPersonPage = () => {
  const tableHeader = ["Name", "Code", "Created On", "Status", "Action(s)"];

  const [modalState, setModalState] = useState({
    isAddEditOpen: false,
    isAllowSendEmail: false,
    data: null,
    index: null,
  });
  const [merchendiserData, setMerchendiserData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [filterState, setFilterState] = useState({
    search: "",
    isActive: true,
    page: 1,
    limit: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedMerchendiseForMassEmail, setSelectedMerchendiseForMassEmail] =
    useState([]);

  const _handleIsChecked = (data) => {
    let isChecked = false;
    if (selectedMerchendiseForMassEmail.length) {
      selectedMerchendiseForMassEmail.includes(data._id)
        ? (isChecked = true)
        : (isChecked = false);
    } else {
      isChecked = false;
    }

    return isChecked;
  };

  const _handleIsAllSelected = () => {
    let isAllSelected = false;

    const matchedObjectsFilter = merchendiserData?.filter((obj) =>
      selectedMerchendiseForMassEmail.includes(obj._id)
    );

    if (matchedObjectsFilter?.length === merchendiserData?.length) {
      isAllSelected = true;
    } else {
      isAllSelected = false;
    }
    return isAllSelected;
  };

  const _manageEmailReceipientState = (
    selectType = "All",
    brandId = null,
    checkedState = false
  ) => {
    let updatedSelectedBrandId = selectedMerchendiseForMassEmail?.length
      ? [...selectedMerchendiseForMassEmail]
      : [];
    if (merchendiserData?.length) {
      switch (selectType) {
        case "All":
          if (checkedState)
            merchendiserData?.forEach((eachBrand) =>
              updatedSelectedBrandId.push(eachBrand?.id)
            );
          else updatedSelectedBrandId = [];
          break;
        case "One":
          if (checkedState) {
            updatedSelectedBrandId.push(brandId);
          } else {
            updatedSelectedBrandId = updatedSelectedBrandId?.filter(
              (eachBrand) => eachBrand !== brandId
            );
          }
          break;
        default:
          break;
      }
    }

    setSelectedMerchendiseForMassEmail(updatedSelectedBrandId);
  };

  const _handleFilterChange = (e) => {
    const { name, value } = e.target;
    let newFilters = { ...filterState };
    newFilters[name] =
      name === "isActive" ? (value === "true" ? true : false) : value;

    setFilterState(newFilters);

    if (name === "search") {
      setIsSearchLoading(true);
      optimizedFn(newFilters);
    } else {
      _fetchAllMerchendisers(newFilters);
    }
  };

  const _checkHandler = async (data) => {
    _fetchAllMerchendisers(data);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  const _handleModalToggle = (
    isAddEditOpen = false,
    isAllowSendEmail = false,
    data = null,
    index = null
  ) => {
    setModalState({ isAddEditOpen, isAllowSendEmail, data, index });
  };

  const _handleClearFilters = () => {
    setFilterState((prev) => {
      let newFilters = { ...prev };
      newFilters["search"] = "";
      newFilters["isActive"] = true;
      newFilters["page"] = 1;
      newFilters["limit"] = 10;

      _fetchAllMerchendisers(newFilters);
      return newFilters;
    });
  };

  const _fetchAllMerchendisers = async (payload) => {
    setIsLoading(true);
    try {
      const res = await fetchAllMerchendiser(payload);

      setMerchendiserData(res.merchandisers);
      setDataCount(res.totalCount);

      setIsSearchLoading(false);
      setIsLoading(false);
    } catch (e) {
      console.log("error fetching merchendiser data", e);
      setIsSearchLoading(false);
      setIsLoading(false);
    }
  };

  const _onPageChange = (pageNumber) => {
    const newDataPayload = deepClone(filterState);
    newDataPayload["page"] = pageNumber + 1;
    setFilterState(newDataPayload);
    _fetchAllMerchendisers(newDataPayload);
  };

  useEffect(() => {
    _fetchAllMerchendisers(filterState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle">
          <h2>Merchandiser</h2>

          <div className="flex-shrink-0 ms-3">
            <Button
              className="addBtn"
              onClick={() => _handleModalToggle(true, false, null, null)}
            >
              <i className="fas fa-plus me-1" />
              Add
            </Button>

            <Button id="toggler" className="mobileFilter">
              <i className="fas fa-filter" />
            </Button>
          </div>
        </div>

        <UncontrolledCollapse toggler="#toggler" isOpen>
          <div className="filterWrapper">
            <Row>
              <Col lg={3} sm={4} xs={12}>
                <FormGroup>
                  <Label>Status</Label>
                  <Input
                    type="select"
                    name="isActive"
                    value={filterState?.isActive}
                    onChange={(e) => _handleFilterChange(e)}
                  >
                    <option value={true}>Active</option>
                    <option value={false}>Inactive</option>
                  </Input>
                </FormGroup>
              </Col>

              <Col lg={3} md={5} sm={6} xs={9}>
                <FormGroup>
                  <Label className="invisible">Search</Label>
                  <InputGroup className="searchTable">
                    <Input
                      type="text"
                      name="search"
                      placeholder="Search"
                      autoComplete="off"
                      value={filterState.search}
                      onChange={(e) => _handleFilterChange(e)}
                    />
                    <Button color="link">
                      {isSearchLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="fa fa-search" />
                      )}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col lg={1} sm={2} xs={3}>
                <div className="mb-3">
                  <Label className="d-block invisible">Reset</Label>
                  <Button
                    outline
                    color="warning"
                    onClick={() => _handleClearFilters()}
                    id="clearFilter"
                  >
                    <i className="fa fa-eraser" />
                  </Button>
                  <UncontrolledTooltip placement="bottom" target="clearFilter">
                    Clear Filter
                  </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
          </div>
        </UncontrolledCollapse>
      </Col>

      <Col xs="12">
        {selectedMerchendiseForMassEmail?.length ? (
          <div className="d-flex" style={{marginBottom: 10}}>
            <Button
              className="addBtn"
              onClick={() => _handleModalToggle(false, true)}
            >
              Send Email
            </Button>
          </div>
        ) : null}

        <Table responsive className="checkBoxTable">
          <thead>
            <tr>
              <th>
                <FormGroup check>
                  <Input
                    id="exampleCheck"
                    name="check"
                    type="checkbox"
                    checked={_handleIsAllSelected()}
                    onChange={(event) =>
                      _manageEmailReceipientState(
                        "All",
                        null,
                        event.target.checked
                      )
                    }
                  />
                </FormGroup>
              </th>
              {tableHeader?.map((eachEnum, eachIndex) => (
                <th key={eachIndex}>{eachEnum}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={6} className="text-center">
                  <Spinner className="spinnerInTable" />
                </td>
              </tr>
            ) : merchendiserData?.length ? (
              merchendiserData?.map((merchendiser, index) => (
                <tr key={`brandList_${merchendiser._id}_${index}`}>
                  <td>
                    <FormGroup check>
                      <Input
                        id="exampleCheck1"
                        name="check"
                        type="checkbox"
                        checked={_handleIsChecked(merchendiser)}
                        onChange={(event) =>
                          _manageEmailReceipientState(
                            "One",
                            merchendiser?.id,
                            event.target.checked
                          )
                        }
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="brandLogo">
                        <img
                          src={
                            merchendiser.profilePic
                              ? merchendiser.profilePic
                              : require("../../assets/img/user.png")
                          }
                          alt="Logo"
                        />
                      </div>
                      <div>
                        {capitalize(
                          merchendiser?.name?.full ||
                            merchendiser?.name?.first ||
                            merchendiser?.name?.last ||
                            "--"
                        )}

                        <div className="mt-1">
                          {/* Phone  */}
                          <i
                            className="fas fa-phone-alt me-3"
                            style={{fontSize: 13}}
                            id={`clientPhone-${index}`}
                            onClick={() =>
                              copyToClipBoard(merchendiser?.phone || "N/A")
                            }
                          />
                          <UncontrolledTooltip
                            target={`clientPhone-${index}`}
                            placement="bottom"
                          >
                            {merchendiser?.phone || "N/A"}
                          </UncontrolledTooltip>

                          {/* Email */}
                          <i
                            className="far fa-envelope"
                            style={{verticalAlign: -0.5}}
                            id={`clientEmail-${index}`}
                            onClick={() =>
                              copyToClipBoard(merchendiser?.email || "N/A")
                            }
                          />
                          <UncontrolledTooltip
                            target={`clientEmail-${index}`}
                            placement="bottom"
                          >
                            {merchendiser?.email || "N/A"}
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{merchendiser?.employeeCode || "-"}</td>
                  <td>
                    {moment(merchendiser?.createdAt).format("MMM DD, YYYY")}
                  </td>
                  <td>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={merchendiser?.isActive}
                        // onClick={() => {
                        //   setState(!state);
                        // }}
                        disabled
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <Button
                      onClick={() =>
                        _handleModalToggle(true, false, merchendiser, index)
                      }
                      className="actionBtn"
                    >
                      <i className="fas fa-pencil-alt" />
                    </Button>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={6} className="text-center">No Data</td>
              </tr>
            )}
          </tbody>
        </Table>
      </Col>

      {merchendiserData?.length && dataCount ? (
        <div className="paginationWrap">
          <div className="tableCount">
            Showing <span>{merchendiserData?.length}</span> entries out of{" "}
            <span>{dataCount}</span>
          </div>
          <PaginatedItems
            forcePage={filterState.page}
            itemsPerPages={merchendiserData?.length}
            totalCount={dataCount}
            selectedPage={filterState.page}
            itemsPerPage={filterState.limit}
            onPageChange={_onPageChange}
          />
        </div>
      ) : null}

      <AddEditSalesPerson
        isOpen={modalState.isAddEditOpen}
        toggle={_handleModalToggle}
        data={modalState?.data}
        index={modalState?.index}
        _refetchData={_fetchAllMerchendisers}
      />

      <SendEmailModal
        isOpen={modalState.isAllowSendEmail}
        toggle={_handleModalToggle}
        checkedState={selectedMerchendiseForMassEmail}
        resetCheckedState={() => setSelectedMerchendiseForMassEmail([])}
        brandData={merchendiserData}
      />
    </>
  );
};

export default SalesPersonPage;
