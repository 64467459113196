import React, { useEffect, useState } from "react";
import {
  Button,
  Input,
  Col,
  Label,
  Nav,
  NavItem,
  TabContent,
  TabPane,
  NavLink,
  Row,
  FormGroup,
  Spinner
} from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import classnames from "classnames";
import MultiStepForm from "../../components/MutiStepForm";
import { showToaster, uploadFilesToCloudinary } from "../../helper-methods";
import { fetchVisitDetailsById, updateVisit } from "../../http/http-calls";
import Default from "../../assets/img/default.png";

const EditVisitPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [activeTab, setActiveTab] = useState(1);
  const [isComponentLoading, setIsComponentLoading] = useState(false);
  const [visitData, setVisitData] = useState(null);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const _handleStateUpdate = (key, value) => {
    if (key === "visit") setVisitData(value);
    else
      setVisitData({
        ...visitData,
        [key]: value,
      });
  };

  const _handleImageUplaod = async (event) => {
    setIsComponentLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);
    _handleStateUpdate("selfieImage", uploadReturnResponse.join(","));
    setIsComponentLoading(false);
  };

  const _isAllowedToSubmit = () => {
    let isAllowed = false;

    visitData?.brandsData?.forEach((each) => {
      if (
        each?.brandId === "" ||
        each?.preMerchandisedImage === "" ||
        each?.postMerchandisedImage === "" ||
        !each?.stockInHand?.length ||
        !each?.newOrders?.length
      )
        isAllowed = false;
      else isAllowed = true;
    });

    return isAllowed;
  };

  const _formatBrandsData = (brands) => {
    const brandsData = brands.map((e) => {
      const obj = {
        brandId: e?._brand?._id,
        preMerchandisedImage: e?.preMerchandisedImage,
        postMerchandisedImage: e?.postMerchandisedImage,
        stockInHand: e?.stockInHand,
        newOrders: e?.newOrders,
        status: e?.status,
      }
      return obj
    })
    return brandsData
  }

  const _updateVisit = async () => {
    try {
      const payload = {
        name: visitData?.name,
        selfieImage: visitData?.selfieImage,
        brandsData: _formatBrandsData(visitData?.brandsData)
      }
      await updateVisit(params?.visitId, payload);
      showToaster("success", "Visit updated successfully");
      navigate(`/merachandiser-shop`);
    } catch (e) {
      showToaster("error", e.reason);
    }
  }

  const _fetchVisitData = async (visitId) => {
    try {
      const res = await fetchVisitDetailsById(visitId);
      setVisitData(res?.visit);
    } catch (e) {
      showToaster("error", e.reason);
    }
  };

  useEffect(() => {
    if (params?.visitId && visitData === null) _fetchVisitData(params?.visitId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  // console.log("test", visitData);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle justify-content-start">
          <Button className="backBtn" onClick={() => navigate(-1)} outline>
            <i className="fas fa-chevron-left" />
          </Button>
          <h2>Edit Visit</h2>
        </div>
      </Col>
      {visitData?.selfieAttendanceStatus === "Approved" ? (
        <Col sm={12}>
          <Row className="visitStepWrap">
            <Col md={6} className="d-flex flex-column justify-content-center">
              <FormGroup className="mb-4">
                <Label>Visit Name</Label>
                <Input value={visitData?.name} disabled={true} />
              </FormGroup>
            </Col>
            <Col md={6} className="text-center">
              <Label>Attendance Selfie</Label>
              <div className="uploadedSelfie">
                <img src={visitData?.selfieImage || Default} alt="Selfie" />
              </div>
            </Col>
          </Row>
        </Col>
      ) : (
        <Col sm={12}>
          <Row className="visitStepWrap">
            <Col md="6" className="d-flex flex-column">
              <FormGroup className="mb-4">
                <Label>Visit Name</Label>
                <Input
                  value={visitData?.name}
                  onChange={(e) => _handleStateUpdate("name", e.target.value)}
                />
              </FormGroup>

              <FormGroup className="text-center text-md-start">
                <Label className="d-block">Attendance (Upload Selfie with Outlet Board)</Label>
                <div className="custom-file-upload-box-gallery mx-auto d-inline-block me-md-auto">
                  {isComponentLoading ? (
                    <Spinner size="sm" />
                  ) : (
                    <Label className="custom-file-upload">
                      <Input
                        type="file"
                        className="d-none"
                        onChange={(event) => _handleImageUplaod(event)}
                        disabled={isComponentLoading}
                      />
                      Edit
                    </Label>
                  )}
                </div>
              </FormGroup>
            </Col>
            <Col md="6">
              <div className="uploadedSelfie">
                <img
                  src={
                    visitData?.selfieImage !== ""
                      ? visitData?.selfieImage
                      : Default
                  }
                  alt="Selfie"
                />
              </div>
            </Col>
          </Row>
        </Col>
      )}

      {/* step 1. After it gets completed, show step 2 */}

      {/* step 2, show this only after step 1 gets completed */}
      <Col sm={12}>
        <div className="visitStepWrap px-3">
          <Nav pills className="customTabs">
            {visitData !== null && visitData?.brandsData?.length
              ? visitData?.brandsData?.map((each, index) => (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === index + 1,
                    })}
                    onClick={() => {
                      toggle(index + 1);
                    }}
                  >
                    {each?._brand?.name?.full}
                  </NavLink>
                </NavItem>
              ))
              : null}
          </Nav>

          <TabContent activeTab={activeTab} className="customTabContent mb-0">
            {visitData !== null && visitData?.brandsData?.length
              ? visitData?.brandsData?.map((each, index) => (
                <TabPane tabId={index + 1} key={index}>
                  <MultiStepForm
                    isViewModeOnly={each?.status === "Approved" ? true : false}
                    visitState={visitData}
                    brandIndex={index}
                    switchTab={toggle}
                    isEditMode={true}
                  />
                </TabPane>
              ))
              : null}
          </TabContent>
        </div>
      </Col>

      <Col sm={12}>
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 30 }}
        >
          {_isAllowedToSubmit() ? (
            <Button
              // type="submit"
              className="addBtn mx-2"
              onClick={() => _updateVisit()}
            >
              Update
            </Button>
          ) : null}
        </div>
      </Col>
    </>
  );
};

export default EditVisitPage;
