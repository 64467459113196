import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import PublicFooter from "../../containers/Public/PublicFooter";
import { addUserCredential } from "../../redux/actions/user-credential";
import { RegexConfig } from "../../config/RegexConfig";
import { login, fetchSettings } from "../../http/http-calls";
import { showToaster } from "../../helper-methods";
import jwtDecode from "jwt-decode";
import { updateSettingsData } from "../../redux/actions/settings-data";

const LoginPage = () => {
  // navigation
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const [loginCreds, setLoginCreds] = useState({
    handle: "",
    password: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let newLoginState = { ...loginCreds };
    let newIsDirty = { ...isDirty };
    newLoginState[name] = value;
    newIsDirty[name] = true;

    setLoginCreds(newLoginState);
    setIsDirty(newIsDirty);

    _validateLoginCreds(newLoginState, newIsDirty);
  };

  const _validateLoginCreds = (newLoginState, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newLoginState).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "handle": {
              if (newLoginState[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newLoginState[key]).toLowerCase()
                  ) ||
                  RegexConfig.phone.test(
                    String(newLoginState[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Please enter a valid email id or phone";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Please enter a valid email id or phone";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (newLoginState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Please enter a valid password";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onSaveDetails = async () => {
    let newIsDirty = {
      handle: true,
      password: true,
    };

    const isFormValid = await _validateLoginCreds(loginCreds, newIsDirty);
    if (isFormValid) {
      // setIsLoading(true);

      let payload = {
        ...loginCreds,
      };

      console.log("payload", payload);
      _handleLoginFlow(payload);
    } else {
      showToaster("error", "Required field(s) missing");
    }
  };

  const _handleLoginFlow = async (payload) => {
    setIsLoading(true);
    try {
      const res = await login(payload);
      console.log("res", res);
      _handleLoginSuccessFlow(res);
    } catch (e) {
      console.log("error loggin in", e);
      showToaster("error", e.reason || "Something went wrong on our end");
      setIsLoading(false);
    }
  };

  const _handleLoginSuccessFlow = (response) => {
    let loggedInRole = jwtDecode(response.token).role;
    dispatch(addUserCredential({ token: response.token }));
    showToaster("success", "Login success");
    if (loggedInRole === "admin") navigate("/shop");
    else if (loggedInRole === "Merchandiser") navigate("/merachandiser-shop");
    else navigate("/brand");
    _fetchSettingsData();
    setIsLoading(false);
  };

  const _fetchSettingsData = async () => {
    setIsLoading(true);
    try {
      const res = await fetchSettings();
      dispatch(updateSettingsData({ settings: res?.setting }));
      setIsLoading(false);
    } catch (e) {
      showToaster("error", e.reason);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is Enter and not Tab
      if (event.key === "Enter") {
        _onSaveDetails();
      }
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

     // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loginCreds]);

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap">
          {/* logo */}
          <img
            src={require("../../assets/img/company-logo-ndr.jpg")}
            alt="Brand Logo"
            className="companyLogo"
          />
          <Form>
            <h2>Login</h2>

            {/* username */}
            <div className="mb-3">
              <Label>Email or Phone</Label>
              <InputGroup>
                <Input
                  placeholder="Enter email or phone"
                  name="handle"
                  value={loginCreds.handle}
                  onChange={(e) => _handleChange(e)}
                  onKeyDown={(event) =>
                    event.key === "Enter" && _onSaveDetails()
                  }
                  disabled={isLoading}
                />
                <InputGroupText>
                  <i className="far fa-envelope"></i>
                </InputGroupText>
              </InputGroup>

              {/* show this when you get any error */}
              {isDirty.handle ? (
                <div className="form-error">{errors.handle}</div>
              ) : null}
            </div>

            {/* password */}
            <div className="mb-2">
              <Label>Password</Label>
              <InputGroup>
                <Input
                  placeholder="Enter"
                  type={`${showPassword ? "text" : "password"}`}
                  name="password"
                  value={loginCreds.password}
                  onChange={(e) => _handleChange(e)}
                  onKeyDown={(event) =>
                    event.key === "Enter" && _onSaveDetails()
                  }
                  disabled={isLoading}
                />
                <InputGroupText
                  className="cursorPointer"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                >
                  <i
                    className={`far ${
                      showPassword ? "fa-eye" : "fa-eye-slash"
                    }`}
                  ></i>
                </InputGroupText>
              </InputGroup>

              {/* show this when you get any error */}
              {isDirty.password ? (
                <div className="form-error">{errors.password}</div>
              ) : null}
            </div>

            <div className="text-end">
              <Link
                to={{ pathname: "/forgot-password" }}
                state={loginCreds}
                className="forgotPassword"
              >
                Forgot Password?
              </Link>
            </div>

            {/* submit button */}
            <Button
              onClick={() => _onSaveDetails()}
              color="primary"
              className="btn-submit"
              disabled={isLoading}
            >
              {isLoading ? <Spinner size="sm" /> : "Login"}
            </Button>
          </Form>
        </div>

        {/* footer */}
        <PublicFooter />
      </div>
    </>
  );
};

export default LoginPage;
