import React from "react";
import { Button, Table } from "reactstrap";
import { formatINR } from "../../helper-methods";
import { useNavigate } from "react-router-dom";

const DynamicTable = ({ type, data }) => {
  const navigate = useNavigate();

  const ownedHeader = ["Shop Code", "Avg. Rent / Shelf", "Avg. Footfall", "Action"];
  const requestedHeader = [
    "Shop Code",
    "Avg. Rent / Shelf",
    "Avg. Footfall",
    "Shelf on Rent",
    "Bid Amount",
    "Total Amount",
  ];

  const _renderTableData = () => {
    switch (type) {
      case "owned":
        return (
          <Table responsive>
            <thead>
              <tr>
                {ownedHeader?.map((each, index) => (
                  <th key={index}>{each}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.length ? (
                data?.map((eachTable, eachIndex) => (
                  <tr key={eachIndex}>
                    <td>{eachTable?.code || "N/A"}</td>
                    <td>
                      <i className="fas fa-rupee-sign" />
                      {formatINR(eachTable?.avgRentPerShelf || 0)}
                    </td>
                    <td> {formatINR(eachTable?.avgFootfall || 0)}</td>
                    <td>
                      <Button
                        onClick={() =>
                          navigate(`/shop-details/${eachTable?._id}`)
                        }
                        className="actionBtn"
                      >
                        <i className="far fa-eye" />
                      </Button>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={4} className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        );
      case "requested":
        return (
          <Table responsive>
            <thead>
              <tr>
                {requestedHeader?.map((each, index) => (
                  <th key={index}>{each}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.length ? (
                data?.map((eachTable, eachIndex) => (
                  <tr key={eachIndex}>
                    <td>{eachTable?._shop?.code || "N/A"}</td>
                    <td>
                      <i className="fas fa-rupee-sign" />
                      {formatINR(eachTable?._shop?.avgRentPerShelf || 0)}
                    </td>
                    <td>{formatINR(eachTable?._shop?.avgFootfall || 0)}</td>
                    <td>{eachTable?.shelfOnRent || 0}</td>
                    <td>
                      <i className="fas fa-rupee-sign" />
                      {formatINR(eachTable?.bidAmount || 0)}
                    </td>
                    <td>
                      <i className="fas fa-rupee-sign" />
                      {formatINR(eachTable?.totalAmount || 0)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        );
      case "rejected":
        return (
          <Table responsive>
            <thead>
              <tr>
                {requestedHeader?.map((each, index) => (
                  <th key={index}>{each}</th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data?.length ? (
                data?.map((eachTable, eachIndex) => (
                  <tr key={eachIndex}>
                    <td>{eachTable?._shop?.code || "N/A"}</td>
                    <td>
                      <i className="fas fa-rupee-sign" />
                      {formatINR(eachTable?._shop?.avgRentPerShelf || 0)}
                    </td>
                    <td>{formatINR(eachTable?._shop?.avgFootfall || 0)}</td>
                    <td>{eachTable?.shelfOnRent || 0}</td>
                    <td>
                      <i className="fas fa-rupee-sign" />
                      {formatINR(eachTable?.bidAmount || 0)}
                    </td>
                    <td>
                      <i className="fas fa-rupee-sign" />
                      {formatINR(eachTable?.totalAmount || 0)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan={6} className="text-center">
                    No data found
                  </td>
                </tr>
              )}
            </tbody>
          </Table>
        );
      default:
        break;
    }
  };

  return <React.Fragment>{_renderTableData()}</React.Fragment>;
};

export default DynamicTable;
