import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  Spinner,
} from "reactstrap";
import { showToaster, uploadFilesToCloudinary } from "../../helper-methods";
import { updateShop } from "../../http/http-calls";
import Default from "../../assets/img/default.png";

const AddVisitDetailsModal = ({
  isOpen,
  data,
  index,
  toggle,
  visitsData,
  shopId,
  _reloadData,
}) => {
  const [localState, setLocalState] = useState({
    label: "",
    description: "",
    sharedPhotos: [],
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);

  const _closeModal = () => {
    _reset();
    toggle();
  };

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let newLocalState = { ...localState };
    let newIsDirty = { ...isDirty };
    newLocalState[name] = value;
    newIsDirty[name] = true;
    setLocalState(newLocalState);
    setIsDirty(newIsDirty);

    _validateState(newLocalState, newIsDirty);
  };

  const _handleImageUplaod = async (event) => {
    setIsModalLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);
    setLocalState({ ...localState, sharedPhotos: uploadReturnResponse });
    setIsModalLoading(false);
  };

  const _removePhotoHandler = (index) => {
    let newPhotoState = localState?.sharedPhotos?.length
      ? [...localState.sharedPhotos]
      : [];
    newPhotoState.splice(index, 1);
    setLocalState({ ...localState, sharedPhotos: newPhotoState });
  };

  const _validateState = (newLocalState, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newLocalState).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "label": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "description": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "sharedPhotos": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
              break;
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onSaveDetails = async () => {
    let newIsDirty = {
      label: true,
      description: true,
      sharedPhotos: true,
    };

    const isFormValid = await _validateState(localState, newIsDirty);
    if (isFormValid) {
      // setIsModalLoading(true);

      let payload = {
        visits: visitsData?.length ? [...visitsData] : [],
      };
      if (data !== null) {
        let localVisits = payload.visits?.length ? [...payload.visits] : [];

        localVisits = payload.visits?.map((obj) => {
          if (obj._id === data?._id) {
            return {
              label: localState.label,
              description: localState.description,
              sharedPhotos: localState.sharedPhotos?.length
                ? [...localState.sharedPhotos]
                : [],
            };
          } else {
            return obj;
          }
        });

        payload.visits = localVisits?.length ? [...localVisits] : [];
      } else {
        payload.visits.push({ ...localState });
      }
      _makeApiCall(payload);
    } else {
      showToaster("error", "Required field(s) missing");
    }
  };

  const _makeApiCall = async (payload) => {
    try {
      await updateShop(shopId, payload);

      showToaster("success", "Visit Updated");
      _closeModal();
      _reloadData(shopId);
    } catch (e) {
      showToaster("error", e.reason || "Something went wrong");
    }
  };

  const _reset = () => {
    setIsModalLoading(false);
    setErrors({});
    setIsDirty({});
    setLocalState({
      label: "",
      description: "",
      sharedPhotos: [],
    });
  };

  const _formatModalData = (data) => {
    setLocalState((prev) => {
      let newLocalState = { ...prev };

      newLocalState["label"] = data?.label || "";
      newLocalState["description"] = data?.description || "";
      newLocalState["sharedPhotos"] = data?.sharedPhotos || [];

      return newLocalState;
    });
  };

  useEffect(() => {
    if (data !== null) {
      _formatModalData(data);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>
        {data !== null ? "Update" : "Add"} Visit
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            {/* <div className="custom-file-upload-box-gallery">
              <Label className="custom-file-upload">
                <Input
                  type="file"
                  className="d-none"
                  // disabled
                  onChange={(event) => _handleImageUplaod(event)}
                  multiple
                />
                Add
              </Label>
              {isDirty.sharedPhotos ? (
                <div className="form-error">{errors.sharedPhotos}</div>
              ) : null} */}

            {/* Uploaded Image */}
            {/* {localState?.sharedPhotos?.length
                ? localState?.sharedPhotos?.map((each, index) => (
                    <div className="custom-file-upload-box coverImg">
                      <img
                        src={
                          each ||
                          "https://images.unsplash.com/photo-1715630914788-71ec3db8bf6b?q=80&w=1887&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                        }
                        alt="Uploaded"
                      />
                      <Button onClick={() => _removePhotoHandler(index)}>
                        <i className="fa fa-times" />
                      </Button>
                    </div>
                  ))
                : null}
            </div> */}
          </Col>

          <Col xs={12}>
            <FormGroup>
              <Label>Label</Label>
              <Input
                placeholder="Enter"
                name="label"
                value={localState.label}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.label ? (
                <div className="form-error">{errors.label}</div>
              ) : null}
            </FormGroup>
            <FormGroup>
              <Label>Description</Label>
              <Input
                type="textarea"
                placeholder="Enter"
                name="description"
                value={localState.description}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
                rows="3"
              />
              {isDirty.description ? (
                <div className="form-error">{errors.description}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={12}>
            <Label className="fs-16 d-block mb-0">Upload Images</Label>
            <small className="text-secondary" style={{ fontSize: 12 }}>
              (Picture Should be in the standard format PNG, JPG and less than
              5MB)
            </small>

            <div className="custom-file-upload-box-gallery">
              <Label className="custom-file-upload">
                <Input
                  type="file"
                  className="d-none"
                  // disabled
                  onChange={(event) => _handleImageUplaod(event)}
                  multiple
                />
                Add
              </Label>
              {isDirty.sharedPhotos ? (
                <div className="form-error">{errors.sharedPhotos}</div>
              ) : null}

              {/* Uploaded Image */}
              {localState?.sharedPhotos?.length
                ? localState?.sharedPhotos?.map((each, index) => (
                    <div
                      className="custom-file-upload-box coverImg"
                      key={index}
                    >
                      <img
                        src={
                          each ||
                          Default
                        }
                        alt="Uploaded"
                      />
                      <Button onClick={() => _removePhotoHandler(index)}>
                        <i className="fa fa-times" />
                      </Button>
                    </div>
                  ))
                : null}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isModalLoading}
          onClick={() => _onSaveDetails()}
        >
          {isModalLoading ? (
            <Spinner size="sm" />
          ) : data !== null ? (
            "Add"
          ) : (
            "Update"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddVisitDetailsModal;
