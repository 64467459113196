import React, { useEffect, useState } from "react";
import { Input, Col, FormGroup, Label, Button, Spinner } from "reactstrap";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import { showToaster } from "../../helper-methods";
import { fetchSettings, updateSettings } from "../../http/http-calls";
import { updateSettingsData } from "../../redux/actions/settings-data";

const SettingsPage = () => {
  const dispatch = useDispatch();

  const settingsData = useSelector((state) => state?.settingsData?.settings);

  const [localSettingsData, setLocalSettingsData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const _formatMultiSelectValue = (array) => {
    let returnArray = [];
    array?.forEach((eachElement, eachIndex) =>
      returnArray?.push({ label: eachElement, value: eachIndex })
    );
    return returnArray;
  };

  const _renderArrayOfStrings = (arrayOfObjects) => {
    let returnArray = [];
    arrayOfObjects?.forEach((each) => returnArray?.push(each?.label));
    return returnArray;
  };

  const _handleMultiselectInput = (key, value) => {
    let updatedSettingsData = { ...localSettingsData };
    if (key === "visitThreshlod") updatedSettingsData[key] = Number(value);
    else
      updatedSettingsData[key] =
        value.value !== "" ? _renderArrayOfStrings(value) : [];

    setLocalSettingsData(updatedSettingsData);
  };

  const _handleSettingsUpdate = async () => {
    let payload = {
      locations: localSettingsData?.locations,
      routes: localSettingsData?.routes,
      visitThreshlod: localSettingsData?.visitThreshlod,
    };

    setIsLoading(true);
    try {
      await updateSettings(payload);
      dispatch(updateSettingsData({ settings: localSettingsData }));
      showToaster("success", "Settings updated");
      setIsLoading(false);
    } catch (e) {
      showToaster("error", e.reason);
      setIsLoading(false);
    }
  };

  const _fetchSettingsData = async () => {
    setIsLoading(true);
    try {
      const res = await fetchSettings();
      setLocalSettingsData(res?.setting);
      dispatch(updateSettingsData({ settings: res?.setting }));
      setIsLoading(false);
    } catch (e) {
      showToaster("error", e.reason);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      settingsData?.settings === null ||
      settingsData?.settings === undefined
    ) {
      _fetchSettingsData();
    } else {
      setLocalSettingsData(settingsData?.settings);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle">
          <h2>Settings</h2>
        </div>
      </Col>

      <Col xs="12">
        <div className="settingsWrap">
          <FormGroup>
            <Label>Location</Label>
            <CreatableSelect
              placeholder="Location"
              classNamePrefix="select"
              isMulti={true}
              menuShouldScrollIntoView={false}
              isSearchable={true}
              value={_formatMultiSelectValue(localSettingsData?.locations)}
              onChange={(e) => _handleMultiselectInput("locations", e)}
              name="location"
              isDisabled={isLoading}
            />
          </FormGroup>

          <FormGroup>
            <Label>Routes</Label>
            <CreatableSelect
              placeholder="Routes"
              classNamePrefix="select"
              isMulti={true}
              menuShouldScrollIntoView={false}
              isSearchable={true}
              value={_formatMultiSelectValue(localSettingsData?.routes)}
              onChange={(e) => _handleMultiselectInput("routes", e)}
              name="routes"
              isDisabled={isLoading}
            />
          </FormGroup>

          <FormGroup>
            <Label>Visits Threshold</Label>
            <Input
              type="number"
              placeholder="Enter"
              value={localSettingsData?.visitThreshlod}
              onChange={(e) =>
                _handleMultiselectInput("visitThreshlod", e.target.value)
              }
              disabled={isLoading}
            />
          </FormGroup>

          <Button
            className="addBtn mx-auto d-block"
            style={{ marginTop: 30 }}
            onClick={() => _handleSettingsUpdate()}
            disabled={isLoading}
          >
            {isLoading ? <Spinner /> : "Save"}
          </Button>
        </div>
      </Col>
    </>
  );
};

export default SettingsPage;
