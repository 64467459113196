import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Label,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import PublicFooter from "../../containers/Public/PublicFooter";
import { forgotPassword } from "../../http/http-calls";
import { showToaster } from "../../helper-methods";

const ForgotPasswordPage = () => {
  const location = useLocation();
  const navigate = useNavigate();

  const [forgotPasswordHandle, setForgotPasswordHandle] = useState({
    handle: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoding] = useState(false);

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let newForgotPasswordHandle = { ...forgotPasswordHandle };
    let newIsDirty = { ...isDirty };
    newForgotPasswordHandle[name] = value;
    newIsDirty[name] = true;

    setForgotPasswordHandle(newForgotPasswordHandle);
    setIsDirty(newIsDirty);
    _validateDetails(newForgotPasswordHandle, newIsDirty);
  };

  const _validateDetails = (forgotPasswordHandle, isDirty) => {
    return new Promise((resolve) => {
      let newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(forgotPasswordHandle).forEach((key) => {
        switch (key) {
          case "handle": {
            if (forgotPasswordHandle[key]?.trim().length) {
              if (
                RegexConfig.email.test(
                  String(forgotPasswordHandle[key]).toLowerCase()
                )
              ) {
                delete newErrors[key];
                isDirty[key] = false;
              } else {
                newErrors[key] = "*Invalid email";
                isFormValid = false;
              }
            } else {
              newErrors[key] = "This is a Required Field";
              isFormValid = false;
            }
            break;
          }
          default:
            break;
        }
      });
      setErrors(newErrors);
      setIsDirty(isDirty);

      resolve(isFormValid);
    });
  };

  const _onSaveDetails = async () => {
    let newIsDirty = {
      handle: true,
    };
    let isFormValid = await _validateDetails(forgotPasswordHandle, newIsDirty);

    if (isFormValid) {
      let payload = {
        ...forgotPasswordHandle,
      };

      _handleForgotPasswordCall(payload);
    } else {
      showToaster("error", "Required Fields Missing");
    }
  };

  const _handleForgotPasswordCall = async (payload) => {
    setIsLoding(true);
    try {
      const response = await forgotPassword(payload);
      console.log("response for forgot password", response);
      _handleSuccessResponse(response);
    } catch (e) {
      showToaster("error", e.reason);
      setIsLoding(false);
    }
  };

  const _handleSuccessResponse = (response) => {
    if (!response.error) {
      showToaster("success", "Reset link sent successfully");
      navigate("/");
    } else {
      showToaster("error");
    }

    setIsLoding(false);
  };

  useEffect(() => {
    if (location.state) {
      setForgotPasswordHandle({ handle: location.state.handle });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.state]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      // Check if the pressed key is Enter and not Tab
      if (event.key === "Enter") {
        _onSaveDetails();
      }
    };

    // Attach the event listener to the document
    document.addEventListener("keydown", handleKeyDown);

    // Cleanup the event listener when the component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [forgotPasswordHandle]);

  return (
    <>
      <div className="authWrapper">
        <div className="loginWrap">
          <img
            src={require("../../assets/img/company-logo-ndr.jpg")}
            alt="Brand Logo"
            className="companyLogo"
          />
          <Form>
            <h2>Forgot Password</h2>

            {/* username */}
            <FormGroup>
              <Label>Email</Label>
              <InputGroup>
                <Input
                  placeholder="Enter registered email"
                  name="handle"
                  value={forgotPasswordHandle?.handle}
                  onChange={(e) => _handleChange(e)}
                  disabled={isLoading}
                />
                <InputGroupText>
                  <i className="far fa-envelope"></i>
                </InputGroupText>
              </InputGroup>

              {/* show this when you get any error */}
              {isDirty.handle ? (
                <div className="form-error">{errors.handle}</div>
              ) : null}
            </FormGroup>

            {/* submit button */}
            <Button
              color="primary"
              className="btn-submit"
              onClick={() => _onSaveDetails()}
              disabled={isLoading}
            >
              {isLoading ? <Spinner /> : "Forgot Password"}
            </Button>

            {/*infoText*/}
            <div className="text-center fs-14 mt-4">
              Back to <Link to="/login">Login</Link>
            </div>
          </Form>
        </div>

        <PublicFooter />
      </div>
    </>
  );
};

export default ForgotPasswordPage;
