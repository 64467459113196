import React, {
  useEffect,
  useState,
  useCallback,
  /*lazy,*/ Suspense,
} from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Spinner,
  UncontrolledTooltip,
  Col,
  UncontrolledCollapse,
  Row,
} from "reactstrap";

import { fetchShops, removeShop } from "../../http/http-calls";
import {
  budgetEnum,
  footfallEnum,
} from "../../config/helper-config";
import {
  _formatListForMultiSelect,
  _formatNotificationForNewBid,
  deepClone,
  showToaster,
} from "../../helper-methods";

import { connectToSocket } from "../../socket-io";
import { newSocket } from "../../socket-io";

import Swal from "sweetalert2";
import Select from "react-select";

import PaginatedItems from "../../components/PaginatedItems";
import BidTableModal from "../../components/modals/BidTableModal";
import AddEditShopModal from "../../components/modals/AddEditShopModal";
import ShopCards from "../../components/Cards/ShopCards";
import ViewShopModal from "../../components/modals/ViewShopModal";
import { useSelector } from "react-redux";

const ShopPage = () => {
  const settingsData = useSelector((state) => state?.settingsData?.settings);
  const [modalState, setModalState] = useState({
    isAddEditOpen: false,
    isViewOpen: false,
    isBidOpen: false,
    isShowFilter: false,
    data: null,
    index: null,
  });
  const [shopData, setShopData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [lastCode, setLastCode] = useState("");
  const [filterState, setFilterState] = useState({
    town: "",
    budget: "",
    footFall: "",
    search: "",
    page: 1,
    limit: 8,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const _handleParentStateUpdate = (updatedState) => {
    setShopData(updatedState);
  };

  const _handleFilterChange = (e) => {
    const { name, value } = e.target;
    let newFilters = { ...filterState };
    newFilters[name] = value;

    setFilterState(newFilters);

    if (name === "search") {
      setIsSearchLoading(true);
      optimizedFn(newFilters);
    } else {
      _fetchAllShopData(newFilters);
    }
  };

  const _handleMultiselectFilter = (key, value) => {
    let newFilters = { ...filterState };
    newFilters[key] = value.value;

    console.log("test", newFilters);
    setFilterState(newFilters);

    _fetchAllShopData(newFilters);
  };

  const _handleRangeFilters = (key, value) => {
    let newFilters = { ...filterState };
    newFilters[key] = value;

    setFilterState(newFilters);

    let pseudoPayload = { ...newFilters };
    pseudoPayload.budget =
      pseudoPayload.budget === ""
        ? ""
        : {
            min:
              pseudoPayload.budget === "2000+"
                ? "2000"
                : pseudoPayload.budget?.split("-")[0],
            max:
              pseudoPayload.budget === "2000+"
                ? ""
                : pseudoPayload.budget?.split("-")[1],
          };

    pseudoPayload.footFall =
      pseudoPayload.footFall === ""
        ? ""
        : {
            min:
              pseudoPayload.footFall === "5000+"
                ? "5000"
                : pseudoPayload.footFall?.split("-")[0],
            max:
              pseudoPayload.footFall === "5000+"
                ? ""
                : pseudoPayload.footFall?.split("-")[1],
          };

    _fetchAllShopData(pseudoPayload);
  };

  const _onPageChange = (pageNumber) => {
    const newDataPayload = deepClone(filterState);
    newDataPayload["page"] = pageNumber + 1;
    setFilterState(newDataPayload);
    _fetchAllShopData(newDataPayload);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const _checkHandler = async (data) => {
    // console.log("check>>", data);
    _fetchAllShopData(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  const _handleModalToggle = (
    isAddEditOpen = false,
    isViewOpen = false,
    isBidOpen = false,
    isShowFilter = false,
    data = null,
    index = null
  ) => {
    setModalState({
      isAddEditOpen,
      isViewOpen,
      isBidOpen,
      isShowFilter,
      data,
      index,
    });
  };

  const _fetchAllShopData = async (payload) => {
    let upadtedPayload = {
      ...payload,
      town: payload.town !== "" ? payload.town.split(" ") : [],
    };
    setIsLoading(true);
    try {
      const shopRes = await fetchShops(upadtedPayload);
      console.log("response of shops", shopRes);
      setShopData(shopRes.shops);
      setDataCount(shopRes.totalCount);
      setLastCode(shopRes.lastCode);

      //subscribe to all shops
      _subscribeToThreadChannel(shopRes.shops);

      setIsSearchLoading(false);
      setIsLoading(false);
    } catch (e) {
      console.log("error fetching shop data", e);
      setIsSearchLoading(false);
      setIsLoading(false);
    }
  };

  const _deleteShopAlert = (shopData, index) => {
    if (!shopData?._id) {
      showToaster("error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this shop.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd0000",
      // cancelButtonColor: "#fff",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        _removeShop(shopData?.id, index);
      }
    });
  };

  const _removeShop = async (id, index) => {
    setIsLoading(true);
    try {
      await removeShop(id);
      showToaster("success", "Deleted successfully");
      _fetchAllShopData(filterState);
      setIsLoading(false);
    } catch (e) {
      console.log("error deleting payment:", e);
      showToaster("error", e.reason);
      setIsLoading(false);
    }
  };

  const _handleClearFilters = () => {
    setFilterState((prev) => {
      let newFilters = { ...prev };
      newFilters["town"] = "";
      newFilters["budget"] = "";
      newFilters["search"] = "";
      newFilters["page"] = 1;
      newFilters["limit"] = 8;

      _fetchAllShopData(newFilters);
      return newFilters;
    });
  };

  const _subscribeToThreadChannel = (shopsArray) => {
    if (shopsArray?.length) {
      shopsArray?.forEach((eachShop) => {
        try {
          const params = { room: `bidData/${eachShop?._id}` };
          newSocket.emit("subscribe", params, function (res) {
            console.log("subscribed>>", res);
          });
        } catch (error) {
          showToaster(
            "error",
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime."
          );
        }
      });
    }
  };

  const _listenToSubscribedSocketConnection = () => {
    newSocket.on("bidAdded", (receiveMessage) => {
      console.log("received>>", receiveMessage);
      showToaster("info", _formatNotificationForNewBid(receiveMessage, "shop"));

      _fetchAllShopData(filterState);
    });
  };

  useEffect(() => {
    _listenToSubscribedSocketConnection();

    // eslint-disable-next-line
  }, [newSocket]);

  useEffect(() => {
    connectToSocket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (shopData === null) _fetchAllShopData(filterState);

    // eslint-disable-next-line
  }, []);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle">
          <h2>NDR Rentals - Your Branding at your next door Kiranas</h2>

          <div className="flex-shrink-0 ms-3">
            <Button
              onClick={() =>
                _handleModalToggle(true, false, false, false, null, null)
              }
              className="addBtn"
            >
              <i className="fas fa-plus me-1" />
              Add
            </Button>

            <Button
              id="toggler"
              className="mobileFilter"
              onClick={() =>
                setModalState({
                  ...modalState,
                  isShowFilter: !modalState.isShowFilter,
                })
              }
            >
              <i className="fas fa-filter" />
            </Button>
          </div>
        </div>

        <UncontrolledCollapse
          toggler="#toggler"
          isOpen
          // isOpen={modalState.isShowFilter}
        >
          <div className="filterWrapper">
            {/* <i className="fas fa-filter filterIcon" /> */}
            <Row>
              <Col xl={3} lg={4} sm={6} xs={12}>
                <FormGroup>
                  <Label>Town</Label>
                  <Select
                    placeholder="Select"
                    classNamePrefix="select"
                    isMulti={false}
                    menuShouldScrollIntoView={false}
                    isSearchable={true}
                    name="town"
                    options={_formatListForMultiSelect(settingsData?.locations)}
                    value={
                      filterState.town !== ""
                        ? { label: filterState?.town, value: filterState?.town }
                        : "Select"
                    }
                    onChange={(e) => _handleMultiselectFilter("town", e)}
                    disabled={isLoading}
                  />
                </FormGroup>
              </Col>

              <Col lg={2} xs={6}>
                <FormGroup>
                  <Label>Budget</Label>
                  <Input
                    type="select"
                    value={filterState.budget}
                    onChange={(e) =>
                      _handleRangeFilters("budget", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {budgetEnum?.map((each, index) => (
                      <option key={index} value={each}>
                        &#x20B9; {each}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col lg={2} xs={6}>
                <FormGroup>
                  <Label>Footfall</Label>
                  <Input
                    type="select"
                    value={filterState.footFall}
                    onChange={(e) =>
                      _handleRangeFilters("footFall", e.target.value)
                    }
                  >
                    <option value="">All</option>
                    {footfallEnum?.map((each, index) => (
                      <option key={index} value={each}>
                        {each}
                      </option>
                    ))}
                  </Input>
                </FormGroup>
              </Col>

              <Col lg={3} sm={4} xs={9}>
                <FormGroup>
                  <Label className="invisible">Search</Label>

                  <InputGroup className="searchTable">
                    <Input
                      type="text"
                      name="search"
                      placeholder="Search"
                      autoComplete="off"
                      value={filterState.search}
                      onChange={(e) => _handleFilterChange(e)}
                    />
                    <Button color="link">
                      {isSearchLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="fa fa-search" />
                      )}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col lg={1} sm={2} xs={3}>
                <div className="mb-3">
                  <Label className="d-block invisible">Reset</Label>
                  <Button
                    outline
                    color="warning"
                    onClick={() => _handleClearFilters()}
                    id="clearFilter"
                    style={{ marginTop: 1 }}
                  >
                    <i className="fa fa-eraser" />
                  </Button>
                  <UncontrolledTooltip placement="bottom" target="clearFilter">
                    Clear Filter
                  </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
          </div>
        </UncontrolledCollapse>
      </Col>

      <Col sm={12} className="ownerProperty">
        <h3 className="sectionTitle">Exclusive Shops for Shelves</h3>
        {isLoading ? (
          <Spinner className="spinnerInTable" />
        ) : (
          <React.Fragment>
            <Suspense fallback={<Spinner />}>
              <ShopCards
                shopData={shopData === null ? [] : shopData}
                toggle={_handleModalToggle}
                _deleteShopAlert={_deleteShopAlert}
              />
            </Suspense>

            {shopData?.length && dataCount ? (
              <div className="paginationWrap">
                <div className="tableCount">
                  Showing <span>{shopData?.length}</span> entries out of{" "}
                  <span>{dataCount}</span>
                </div>
                <Suspense fallback={<Spinner size="sm" />}>
                  <PaginatedItems
                    forcePage={filterState.page}
                    itemsPerPages={shopData?.length}
                    totalCount={dataCount}
                    selectedPage={filterState.page}
                    itemsPerPage={filterState.limit}
                    onPageChange={_onPageChange}
                  />
                </Suspense>
              </div>
            ) : null}
          </React.Fragment>
        )}
      </Col>

      <Suspense fallback={<Spinner />}>
        <AddEditShopModal
          isOpen={modalState.isAddEditOpen}
          data={modalState.data}
          index={modalState.index}
          toggle={_handleModalToggle}
          lastCode={lastCode}
          _refetchData={() => _fetchAllShopData(filterState)}
        />

        <ViewShopModal
          isOpen={modalState.isViewOpen}
          data={modalState.data}
          index={modalState.index}
          toggle={_handleModalToggle}
          _refetchData={() => _fetchAllShopData(filterState)}
        />

        <BidTableModal
          isOpen={modalState.isBidOpen}
          data={modalState.data}
          index={modalState.index}
          toggle={_handleModalToggle}
          shopData={shopData}
          _handleParentStateUpdate={_handleParentStateUpdate}
          _refetchData={() => _fetchAllShopData(filterState)}
          userType={"shop"}
        />
      </Suspense>
    </>
  );
};

export default ShopPage;
