import React, { useState, useEffect } from "react";
import { fetchLoggedInDashboardData, generateReportCSV } from "../../http/http-calls";

import ShopDashboardAmountCard from "../../components/Cards/ShopDashboardAmountCard";
import BidTableCards from "../../components/Cards/BidTableCards";
import ShopDashboardPieChart from "../../components/Cards/ShopDashboardPieChart";
import ShopDashboardLineChart from "../../components/Cards/ShopDashboardLineChart";
import { Col, Row, Spinner, Label, Input } from "reactstrap";

const ShopDashboard = (props) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const _fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const dashboardRes = await fetchLoggedInDashboardData();
      setDashboardData(dashboardRes);
      setIsLoading(false);
    } catch (e) {
      console.log("error fetching dashboard data", e);
      setIsLoading(false);
    }
  };

  const _handleFileUpload = async (event) => {
    setIsLoading(true);
    const formData = new FormData();
    formData.append("csv-file", event.target.files[0])
    try {
      await generateReportCSV(formData);
      event.target.value = null;
      // _fetchDashboardData();
      setIsLoading(false);
    } catch (error) {
      console.error("Error uploading file:", error);
      setIsLoading(false);
    }
  }

  useEffect(() => {
    if (dashboardData === null) _fetchDashboardData();

    // eslint-disable-next-line
  }, [props]);

  return (
    <React.Fragment>
      <Col sm={12}>
        <div className="pgTitle">
          <h2>Dashboard</h2>

          <div className="flex-shrink-0 ms-3">
            <Label className="d-block mb-0">Upload Report
            </Label>
            <small className="text-secondary" style={{ fontSize: 12 }}>
              (File Should be in the standard format CSV and less than
              5MB)
            </small>
            <Input
              type="file"
              onChange={(event) => _handleFileUpload(event)}
            />
          </div>
        </div>
      </Col>

      {isLoading ? (
        <Spinner className="spinnerInTable" />
      ) : (
        <React.Fragment>
          {" "}
          <Col sm={12}>
            <Row>
              <Col md={6}>
                <ShopDashboardAmountCard
                  renderType="totalRevenue"
                  renderData={dashboardData?.totalRevenue}
                />
              </Col>
              {/* <Col md={6} md={3}>
                <ShopDashboardAmountCard
                  renderType="noOfBrands"
                  renderData={dashboardData?.noOfBrands}
                />
              </Col>
              <Col md={6} md={3}>
                <ShopDashboardAmountCard
                  renderType="noOfShelves"
                  renderData={dashboardData?.noOfShelves}
                />
              </Col> */}
              <Col md={6}>
                <ShopDashboardAmountCard
                  renderType="approvedBids"
                  renderData={dashboardData?.approvedBids}
                />
              </Col>
            </Row>
          </Col>
          <Col sm={12}>
            <BidTableCards
              tableData={dashboardData?.bids || []}
              _reload={_fetchDashboardData}
            />
          </Col>
          <Col sm={12} lg={6} style={{ marginTop: 15 }}>
            <ShopDashboardPieChart pieChartData={dashboardData} />
          </Col>
          <Col sm={12} lg={6} style={{ marginTop: 15 }}>
            <ShopDashboardLineChart lineChartData={dashboardData} />
          </Col>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default ShopDashboard;
