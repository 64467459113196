import React, { useEffect, useState } from "react";
import {
  Col,
  Modal,
  ModalFooter,
  ModalHeader,
  ModalBody,
  Row,
  Input,
  InputGroup,
  Label,
  InputGroupText,
  FormGroup,
  Button,
  Spinner,
} from "reactstrap";
import { handlePlaceBid } from "../../http/http-calls";
import { showToaster } from "../../helper-methods";

const AddBidModal = ({
  isOpen,
  toggle,
  data,
  index,
  shopData,
  _handleUpdateParentState,
}) => {
  const [localState, setLocalState] = useState({
    bidAmount: "",
    shelfOnRent: "",
    months: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let updatedLocalState = { ...localState };
    let newIsDirty = { ...isDirty };

    updatedLocalState[name] = value;
    newIsDirty[name] = true;

    setLocalState(updatedLocalState);
    setIsDirty(newIsDirty);

    _validateFunction(updatedLocalState, newIsDirty);
  };

  const _validateFunction = (newLocalState, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newLocalState).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "bidAmount": {
              if (newLocalState[key]?.length) {
                if (Number(newLocalState[key]) < 500) {
                  newErrors[key] = "*Minimum bid amount is Rs.500";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "shelfOnRent": {
              if (newLocalState[key]?.length) {
                if (
                  Number(newLocalState[key]) > Number(data?.noOfShelfAvailable)
                ) {
                  newErrors[
                    key
                  ] = `*Cannot be more than total available shelves (Available: ${data?.noOfShelfAvailable})`;
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "months": {
              if (newLocalState[key]?.length) {
                if (Number(newLocalState[key]) < 1) {
                  newErrors[key] = "*Minimum month is 1";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _handlePlacingBids = async () => {
    let newIsDirty = {
      bidAmount: false,
      shelfOnRent: true,
      months: true,
    };

    const isFormValid = await _validateFunction(localState, newIsDirty);
    if (isFormValid) {
      // setIsLoading(true);

      let payload = {
        bidAmount: Number(localState?.bidAmount),
        shelfOnRent: Number(localState?.shelfOnRent),
        months: Number(localState?.months),
      };

      _makeApiCall(payload);
    } else {
      showToaster("error");
    }
  };

  const _makeApiCall = async (payload) => {
    setIsLoading(true);
    try {
      const createBidRes = await handlePlaceBid(data?._id, payload);
      console.log("response creating bid", createBidRes);

      let updatedShopState = shopData?.length ? [...shopData] : [];
      updatedShopState.splice(index, 1, createBidRes?.shop);
      _handleUpdateParentState(updatedShopState);
      setIsLoading(false);

      showToaster("success", "Bid placed successfully");
      setIsLoading(false);
      _closeModal();
    } catch (e) {
      console.log("error placing bid", e);
      showToaster("error", e.reason);
      setIsLoading(false);
    }
  };

  const _closeModal = () => {
    _reset();
    toggle();
  };

  const _reset = () => {
    setLocalState({
      bidAmount: "",
      shelfOnRent: "",
      months: "",
    });
    setIsDirty({});
    setErrors({});
    setIsLoading(false);
  };

  const _formatModalData = (data) => {
    setLocalState((prev) => {
      let newLocalState = { ...localState };
      newLocalState["bidAmount"] =
        data?.avgRentPerShelf || prev.bidAmount || "";
      return newLocalState;
    });
  };

  useEffect(() => {
    if (data !== null) _formatModalData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Modal isOpen={isOpen} toggle={() => _closeModal()} scrollable centered>
      <ModalHeader toggle={() => _closeModal()}>Place Your Bid</ModalHeader>
      <ModalBody>
        <Row>
          <Col sm={6}>
            <FormGroup>
              <Label>Place Your Bid</Label>
              <InputGroup>
                <InputGroupText>
                  <i className="fas fa-rupee-sign" />
                </InputGroupText>
                <Input
                  type="number"
                  placeholder="Bid Price"
                  name="bidAmount"
                  value={localState?.bidAmount || 0}
                  onChange={(e) => _handleChange(e)}
                />
              </InputGroup>
              {isDirty.bidAmount ? (
                <div className="form-error">{errors.bidAmount}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label>Shelves Needed</Label>
              <Input
                type="number"
                placeholder="Enter"
                name="shelfOnRent"
                value={localState.shelfOnRent}
                onChange={(e) => _handleChange(e)}
              />
              {isDirty.shelfOnRent ? (
                <div className="form-error">{errors.shelfOnRent}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label>Months For Rent</Label>
              <Input
                type="number"
                placeholder="Enter"
                name="months"
                value={localState?.months}
                onChange={(e) => _handleChange(e)}
              />

              {isDirty.months ? (
                <div className="form-error">{errors.months}</div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button
          className="closeBtn"
          onClick={() => _closeModal()}
          disabled={isLoading}
        >
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _handlePlacingBids()}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : "Place Your Bid"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddBidModal;
