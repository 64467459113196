import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import { createShop, updateShop } from "../../http/http-calls";
import {
  _formatListForMultiSelect,
  _handleGenerateCode,
  showToaster,
  uploadFilesToCloudinary,
} from "../../helper-methods";
import Select from "react-select";
import { citiesAndStateEnum } from "../../config/helper-config";
import Default from "../../assets/img/default.png";
import { useSelector } from "react-redux";

const AddEditShopModal = ({
  isOpen,
  data,
  index,
  toggle,
  lastCode,
  _refetchData,
}) => {
  const settingsData = useSelector((state) => state?.settingsData?.settings);
  const [localState, setLocalState] = useState({
    name: "",
    phone: "",
    noOfShelf: "",
    avgRentPerShelf: "",
    avgFootfall: "",
    lineOne: "",
    town: "",
    city: "",
    state: "",
    country: "India",
    zipCode: "",
    sharedPhotos: [],
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);

  const _closeModal = () => {
    _reset();
    toggle();
  };

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let newLocalState = { ...localState };
    let newIsDirty = { ...isDirty };
    newLocalState[name] = value;
    newIsDirty[name] = true;

    setLocalState(newLocalState);
    setIsDirty(newIsDirty);

    _validateState(newLocalState, newIsDirty);
  };

  const _handleMultiselectInput = (key, value) => {
    let updatedModalState = { ...localState };
    let newIsDirty = { ...isDirty };
    updatedModalState[key] = value.value !== "" ? value.value || value.key : "";
    newIsDirty[key] = true;
    setLocalState(updatedModalState);
    setIsDirty(newIsDirty);

    _validateState(updatedModalState, newIsDirty);
  };

  const _handleImageUplaod = async (event) => {
    setIsModalLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);
    setLocalState({ ...localState, sharedPhotos: uploadReturnResponse });
    setIsModalLoading(false);
  };

  const _removePhotoHandler = (index) => {
    let newPhotoState = localState?.sharedPhotos?.length
      ? [...localState.sharedPhotos]
      : [];
    newPhotoState.splice(index, 1);
    setLocalState({ ...localState, sharedPhotos: newPhotoState });
  };

  const _validateState = (newLocalState, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newLocalState).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "email": {
              if (newLocalState[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newLocalState[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Please enter a valid email id";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Please enter a valid email id";
                isFormValid = false;
              }
              break;
            }
            case "name": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (newLocalState[key]?.length) {
                if (newLocalState[key]?.length !== 10) {
                  newErrors[key] = "*Invalid contact info";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "avgFootfall": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "noOfShelf": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "avgRentPerShelf": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "lineOne": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "town": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "city": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "state": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "country": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "zipCode": {
              if (newLocalState[key]?.length) {
                if (newLocalState[key]?.length !== 6) {
                  newErrors[key] = "*Invalid zipcode";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "sharedPhotos": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }

            default:
              break;
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onSaveDetails = async () => {
    let newIsDirty = {
      name: true,
      phone: false,
      noOfShelf: true,
      avgRentPerShelf: true,
      avgFootfall: true,
      lineOne: false,
      town: true,
      city: false,
      state: false,
      country: true,
      zipCode: false,
      sharedPhotos: true,
    };

    const isFormValid = await _validateState(localState, newIsDirty);
    if (isFormValid) {
      // setIsModalLoading(true);

      let payload = {
        ...localState,
        phone: Number(localState.phone),
        noOfShelf: Number(localState.noOfShelf),
        avgRentPerShelf: Number(localState.avgRentPerShelf),
        avgFootfall: Number(localState.avgFootfall),
        address: {
          lineOne: localState.lineOne,
          town: localState.town,
          city: localState.city,
          state: localState.state,
          country: localState.country,
          zipCode: localState.zipCode,
        },
      };

      if (data === null) payload.code = _handleGenerateCode(lastCode);

      delete payload.lineOne;
      delete payload.town;
      delete payload.city;
      delete payload.state;
      delete payload.country;
      delete payload.zipCode;

      if (data === null) _handleCreateShack(payload);
      else _handleUpdateShack(payload);
    } else {
      showToaster("error", "Required field(s) missing");
    }
  };

  const _handleCreateShack = async (payload) => {
    setIsModalLoading(true);
    try {
      const shackCreateRes = await createShop(payload);
      console.log("response creating shack", shackCreateRes);
      _closeModal();
      showToaster("success", "Added successfully");

      _refetchData();
      setIsModalLoading(false);
    } catch (e) {
      console.log("error creating shack", e);
      showToaster("error", e.reason);
      setIsModalLoading(false);
    }
  };

  const _handleUpdateShack = async (payload) => {
    setIsModalLoading(true);
    try {
      const shackUpdateRes = await updateShop(data?.id, payload);
      console.log("response updating shack", shackUpdateRes);
      _closeModal();

      showToaster("success", "Updated successfully");

      _refetchData();
      setIsModalLoading(false);
    } catch (e) {
      console.log("error updating shack", e);
      setIsModalLoading(false);
    }
  };

  const _reset = () => {
    setIsModalLoading(false);
    setErrors({});
    setIsDirty({});
    setLocalState({
      name: "",
      phone: "",
      noOfShelf: "",
      avgRentPerShelf: "",
      avgFootfall: "",
      lineOne: "",
      town: "",
      city: "",
      state: "",
      country: "India",
      zipCode: "",
      sharedPhotos: [],
    });
  };

  const _formatModalData = (data) => {
    setLocalState((prev) => {
      let newLocalState = { ...localState };

      newLocalState["name"] = data?.name || prev.name || "";
      newLocalState["phone"] = data?.phone || prev.phone || "";
      newLocalState["noOfShelf"] =
        data?.noOfShelf?.toString() || prev.noOfShelf?.toString() || "";
      newLocalState["avgRentPerShelf"] =
        data?.avgRentPerShelf?.toString() ||
        prev.avgRentPerShelf?.toString() ||
        "";
      newLocalState["avgFootfall"] =
        data?.avgFootfall?.toString() || prev?.avgFootfall?.toString() || "";
      newLocalState["lineOne"] = data?.address?.lineOne || prev.lineOne || "";
      newLocalState["town"] = data?.address?.town || prev.town || "";
      newLocalState["city"] = data?.address?.city || prev.city || "";
      newLocalState["state"] = data?.address?.state || prev.state || "";
      newLocalState["country"] =
        data?.address?.country || prev.country || "India";
      newLocalState["zipCode"] = data?.address?.zipCode || prev.zipCode || "";

      return newLocalState;
    });
  };

  useEffect(() => {
    if (data !== null) _formatModalData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data !== null ? "Update" : "Create"}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12} lg={6}>
            <FormGroup>
              <Label>Shop Name</Label>
              <Input
                placeholder="Enter"
                name="name"
                value={localState.name}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.name ? (
                <div className="form-error">{errors.name}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Phone</Label>
              <Input
                placeholder="Enter"
                type="number"
                value={localState.phone}
                name="phone"
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.phone ? (
                <div className="form-error">{errors.phone}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>Average Footfall</Label>
              <Input
                type="number"
                placeholder="Enter"
                name="avgFootfall"
                value={localState.avgFootfall}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.avgFootfall ? (
                <div className="form-error">{errors.avgFootfall}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>Shack Availability</Label>
              <Input
                type="number"
                placeholder="Enter shacks"
                name="noOfShelf"
                value={localState.noOfShelf}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.noOfShelf ? (
                <div className="form-error">{errors.noOfShelf}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>Price Per Shack</Label>
              <InputGroup>
                <InputGroupText>
                  <i className="fas fa-rupee-sign" />
                </InputGroupText>
                <Input
                  type="number"
                  placeholder="Price"
                  name="avgRentPerShelf"
                  value={localState.avgRentPerShelf}
                  onChange={(e) => _handleChange(e)}
                  disabled={isModalLoading}
                />
              </InputGroup>
              {isDirty.avgRentPerShelf ? (
                <div className="form-error">{errors.avgRentPerShelf}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={12}>
            <FormGroup>
              <Label>Street</Label>
              <Input
                placeholder="Street"
                name="lineOne"
                value={localState.lineOne}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.lineOne ? (
                <div className="form-error">{errors.lineOne}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>Town</Label>
              <Select
                placeholder="Select"
                classNamePrefix="select"
                isMulti={false}
                menuShouldScrollIntoView={false}
                isSearchable={true}
                options={_formatListForMultiSelect(settingsData?.locations)}
                value={{ label: localState.town, value: localState.town }}
                onChange={(e) => _handleMultiselectInput("town", e)}
                disabled={isModalLoading}
              />
              {isDirty.town ? (
                <div className="form-error">{errors.town}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>City</Label>
              <Input
                type="select"
                name="city"
                value={localState.city}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              >
                <option value="">Select</option>
                {citiesAndStateEnum?.map((eachEnum, _) => (
                  <option key={eachEnum?.id}>
                    {eachEnum?.name || eachEnum?.id || "N/A"}
                  </option>
                ))}
              </Input>
              {isDirty.city ? (
                <div className="form-error">{errors.city}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>State</Label>
              <Input
                type="select"
                name="state"
                value={localState.state}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              >
                <option value="">Select</option>
                {citiesAndStateEnum?.map((eachEnum, _) => (
                  <option key={eachEnum?.id}>
                    {eachEnum?.state || eachEnum?.id || "N/A"}
                  </option>
                ))}
              </Input>
              {isDirty.state ? (
                <div className="form-error">{errors.state}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>Country</Label>
              <Input
                placeholder="Country"
                name="country"
                value={localState.country}
                onChange={(e) => _handleChange(e)}
                disabled={true}
              />
              {isDirty.country ? (
                <div className="form-error">{errors.country}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={6} lg={4}>
            <FormGroup>
              <Label>Zip Code</Label>
              <Input
                placeholder="Zip Code"
                name="zipCode"
                value={localState.zipCode}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.zipCode ? (
                <div className="form-error">{errors.zipCode}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={12}>
            <Label className="fs-16 d-block mb-0">Upload Images</Label>
            <small className="text-secondary" style={{ fontSize: 12 }}>
              (Picture Should be in the standard format PNG, JPG and less than
              5MB)
            </small>

            <div className="custom-file-upload-box-gallery">
              <Label className="custom-file-upload">
                <Input
                  type="file"
                  className="d-none"
                  // disabled
                  onChange={(event) => _handleImageUplaod(event)}
                  multiple
                />
                Add
              </Label>
              {isDirty.sharedPhotos ? (
                <div className="form-error">{errors.sharedPhotos}</div>
              ) : null}

              {/* Uploaded Image */}
              {localState?.sharedPhotos?.length
                ? localState?.sharedPhotos?.map((each, index) => (
                    <div className="custom-file-upload-box coverImg">
                      <img
                        src={
                          each ||
                          Default
                        }
                        alt="Uploaded"
                      />
                      <Button onClick={() => _removePhotoHandler(index)}>
                        <i className="fa fa-times" />
                      </Button>
                    </div>
                  ))
                : null}
            </div>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isModalLoading}
          onClick={() => _onSaveDetails()}
        >
          {isModalLoading ? (
            <Spinner size="sm" />
          ) : data === null ? (
            "Create"
          ) : (
            "Update"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditShopModal;
