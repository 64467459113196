import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Button, Table } from "reactstrap";
import { decodeToken, formatINR, showToaster } from "../../helper-methods";
import { handleApproveRejectBid } from "../../http/http-calls";

const BidTableCards = ({ tableData, _reload }) => {
  const userData = decodeToken(
    useSelector((state) => state.userCredential?.token)
  );

  const [localTableData, setLocalTableData] = useState(
    tableData?.length ? [...tableData] : []
  );

  const _handleBidApproveReject = (bidData, bidIndex, type) => {
    switch (type) {
      case "approve":
        _makeApiCall(bidData?._id, "Approved", bidIndex);
        break;
      case "reject":
        _makeApiCall(bidData?._id, "Declined", bidIndex);
        break;
      default:
        break;
    }
  };

  const _makeApiCall = async (bidId, bidStatus, index) => {
    try {
      const response = await handleApproveRejectBid(bidId, {
        status: bidStatus,
      });
      console.log("response updating bid status", response);

      // refresh updated data now
      // let updatedLocalTableData = localTableData?.length
      //   ? [...localTableData]
      //   : [];

      // updatedLocalTableData[index] = response?.bid;

      // setLocalTableData(updatedLocalTableData);
      _reload();

      showToaster("success", "Bid status updated");
    } catch (e) {
      console.log("error accepting rejecting data", e);
    }
  };

  const _renderBidStatus = (shopData, index) => {
    switch (shopData?.status) {
      case "Approved":
        return <span className="statusApproved">Approved</span>;
      case "Declined":
        return <span className="statusDeclined">Declined</span>;
      case "Sent": {
        if (userData?.role === "Brand") {
          return <span className="statusSent">Sent</span>;
        } else {
          return (
            <React.Fragment>
              <Button
                onClick={() =>
                  _handleBidApproveReject(shopData, index, "approve")
                }
                // disabled={isLoading}
                color="success"
                outline
                className="tableActionBtn me-1"
              >
                Approve
              </Button>
              &nbsp;
              <Button
                onClick={() =>
                  _handleBidApproveReject(shopData, index, "reject")
                }
                // disabled={isLoading}
                color="danger"
                outline
                className="tableActionBtn mt-md-2 mt-lg-0"
              >
                Reject
              </Button>
            </React.Fragment>
          );
        }
      }
      default:
        break;
    }
  };

  useEffect(() => {
    if (tableData !== null || tableData !== undefined)
      setLocalTableData(tableData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData]);

  return (
    <React.Fragment>
      <h3 className="sectionTitle" style={{ marginTop: 20 }}>
        Bid Table
      </h3>
      <Table responsive className="bidsTable">
        <thead>
          <tr>
            <th>Shop</th>
            {userData?.role !== "Brand" ? <th>Brand</th> : null}
            <th>Bid Status</th>
            <th>Bid Amount</th>
            <th>Shelves On Rent</th>
            <th>Month(s)</th>
            <th>Total Amount</th>
          </tr>
        </thead>
        <tbody>
          {localTableData?.length ? (
            localTableData?.map((eachData, eachIndex) => (
              <tr key={eachIndex}>
                <td>{eachData?._shop?.code || `Shop-${eachIndex + 1}`}</td>
                {userData?.role !== "Brand" ? (
                  <td>
                    {eachData?._addedBy?.name?.full ||
                      eachData?._addedBy?.name?.first ||
                      "--"}
                  </td>
                ) : null}
                <td>{_renderBidStatus(eachData, eachIndex)}</td>
                <td>
                  <i className="fas fa-rupee-sign" />
                  {`${formatINR(eachData?.bidAmount || 0)}`}
                </td>
                <td>{eachData?.shelfOnRent || 0}</td>
                <td>{eachData?.months || 0}</td>
                <td>
                  <i className="fas fa-rupee-sign" />
                  {`${formatINR(eachData?.totalAmount || 0)}`}
                </td>
              </tr>
            ))
          ) : (
            <tr>
              <td colSpan={7} className="text-center">
                No Bids Placed Yet
              </td>
            </tr>
          )}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default BidTableCards;
