import React, { useState } from "react";
import { Routes, Route, Navigate } from "react-router-dom";

import ProtectedRoute from "../../components/routes/ProtectedRoute";
import ShopPage from "../../pages/protected-pages/ShopPage";
import BrandPage from "../../pages/protected-pages/BrandPage";
import BrandsPage from "../../pages/protected-pages/BrandListPage";
import MediaQueueViewer from "../MediaQueueViewer";
import DashboardFooter from "./DashboardFooter";
import DashboardHeader from "./DashboardHeader";
import ShopDashboard from "../../pages/protected-pages/ShopDashboard";
import BrandDashboard from "../../pages/protected-pages/BrandDashboard";
import ShopDetailsPage from "../../pages/protected-pages/ShopDetailsPage";
import { Container, Row } from "reactstrap";
import SalesPersonPage from "../../pages/protected-pages/SalesPersonPage";
import VisitPage from "../../pages/protected-pages/VisitPage";
// import DashboardSidebar from "./DashboardSidebar";
import SettingsPage from "../../pages/protected-pages/SettingsPage";
import MerchandiserShopPage from "../../pages/protected-pages/MerchandiserShopPage";
import ViewVisitPage from "../../pages/protected-pages/ViewVisitPage";
import EditVisitPage from "../../pages/protected-pages/EditVisitPage";

const DashboardLayout = ({ props }) => {
  const [isShow, setIsShow] = useState(false);
  return (
    <div className="mainWrapper">
      <DashboardHeader isShow={isShow} setIsShow={setIsShow} />
      <Container className="contentWrap">
        {/* <DashboardSidebar isShow={isShow} setIsShow={setIsShow} /> */}
        <Row>
          <Routes>
            <Route
              path=""
              element={<ProtectedRoute redirectRoute={"/login"} />}
            >
              {/* Admin Login */}
              <Route exact path="/shop-dashboard" element={<ShopDashboard />} />
              <Route exact path="/shop" element={<ShopPage />} />
              <Route
                exact
                path="/shop-details/:id"
                element={<ShopDetailsPage />}
              />
              <Route exact path="/brands" element={<BrandsPage />} />
              <Route exact path="/sales" element={<SalesPersonPage />} />
              <Route exact path="/visit/:shopId" element={<VisitPage />} />
              <Route exact path="/view-visit/:visitId" element={<ViewVisitPage />} />
              <Route exact path="/settings" element={<SettingsPage />} />

              {/* Brand Login */}
              <Route
                exact
                path="/brand-dashboard"
                element={<BrandDashboard />}
              />
              <Route exact path="/brand" element={<BrandPage />} />

              {/* Merchendiser Login */}
              <Route
                exact
                path="/merachandiser-shop"
                element={<MerchandiserShopPage />}
              />
              <Route exact path="/edit-visit/:visitId" element={<EditVisitPage />} />
            </Route>

            <Route path="*" element={<Navigate replace to="/brand" />} />
          </Routes>
          <MediaQueueViewer />
        </Row>
      </Container>
      <DashboardFooter />
    </div>
  );
};

export default DashboardLayout;
