import React, { useState } from "react";
import { Button, Label, Input, Spinner, Row, Col } from "reactstrap";
import { uploadFilesToCloudinary } from "../helper-methods";
import Default from "../assets/img/default.png";

const StepTwo = ({
  visitState,
  brandIndex,
  _onUpdate,
  nextStep,
  prevStep,
  updateStep,
  isViewModeOnly,
}) => {
  const [isComponentLoading, setIsComponentLoading] = useState(false);

  const _handleImageUplaod = async (event) => {
    setIsComponentLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);

    let updatedVisitData = { ...visitState };
    updatedVisitData.brandsData[brandIndex].postMerchandisedImage =
      uploadReturnResponse.join(",");

    _onUpdate("visit", updatedVisitData);
    setIsComponentLoading(false);
  };

  return (
    <>
      <div className="step-indicator">
        <div className="step" onClick={() => updateStep(1)}>
          Pre-Merchandised Image
        </div>
        <div className="step active">Post-Merchandised Image</div>
        <div className="step" onClick={() => updateStep(3)}>
          Stock In-hand
        </div>
        <div className="step" onClick={() => updateStep(4)}>
          New Order
        </div>
      </div>

      <Row>
        <Col
          md="6"
          className="d-flex flex-column justify-content-center align-items-center"
        >
          <Label>Post-merchandised Image</Label>
          <div className="custom-file-upload-box-gallery mb-3">
            {isComponentLoading ? (
              <Spinner size="sm" />
            ) : !isViewModeOnly ? (
              <Label className="custom-file-upload">
                <Input
                  type="file"
                  className="d-none"
                  onChange={(event) => _handleImageUplaod(event)}
                  disabled={isComponentLoading}
                />
                Add
              </Label>
            ) : null}
          </div>
        </Col>
        <Col md="6">
          <div className="merchandisedImage">
            <img
              src={
                visitState?.brandsData[brandIndex]?.postMerchandisedImage !== ""
                  ? visitState?.brandsData[brandIndex]?.postMerchandisedImage
                  : Default
              }
              alt="Merchandise"
            />
          </div>
        </Col>
      </Row>

      {/* {visitState?.brandsData[brandIndex]?.postMerchandisedImage !== "" ? ( */}
        <div
          className="d-flex justify-content-center"
          style={{ marginTop: 30 }}
        >
          <Button className="prevBtn mx-2" onClick={prevStep}>
            Prev
          </Button>
          <Button className="addBtn mx-2" onClick={nextStep}>
            Next
          </Button>
        </div>
      {/* ) : null} */}
    </>
  );
};

export default StepTwo;
