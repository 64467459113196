import React, { useState } from "react";
import { Button, FormGroup, Input, Label, Table } from "reactstrap";
import AddVisitDetailsModal from "./modals/AddVisitDetailsModal";
import CarouselModal from "./modals/CarouselModal";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { showToaster } from "../helper-methods";
import { updateVisit } from "../http/http-calls";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";

const VisitsComponent = ({
  shopId,
  visitsData,
  _reloadData,
  _deleteVisitAlert,
}) => {
  const navigate = useNavigate();
  const reduxDataToken = useSelector((state) => state?.userCredential?.token);
  const userData = jwtDecode(reduxDataToken);

  const tableHeaders = [
    "Label",
    "Added On",
    "Added By",
    "View",
    "Status",
    "Action",
  ];
  const tableHeaderBrand = ["Label", "Added On", "Added By", "View", "Status"];

  const [modalState, setModalState] = useState({
    isAddEditOpen: false,
    isViewPicturesOpen: false,
    data: null,
    index: null,
  });

  const _handleModalToggle = (
    isAddEditOpen = false,
    isViewPicturesOpen = false,
    data = null,
    index = null
  ) => {
    setModalState({ isAddEditOpen, isViewPicturesOpen, data, index });
  };

  const _updateVisit = async (id, value) => {
    try {
      await updateVisit(id, { isVisibleForBrand: value });

      _reloadData();
    } catch (e) {
      showToaster("error", e.reason);
    }
  };

  return (
    <React.Fragment>
      <div className="d-flex justify-content-between align-items-center my-3">
        <h3 className="sectionTitle my-0">Visits</h3>

        {/* <Button className="addBtn" onClick={() => navigate(`/visit/${shopId}`)}>
          <i className="fas fa-plus me-1" />
          Add
        </Button> */}
      </div>

      <Table responsive>
        <thead>
          <tr>
            {userData?.role === "admin"
              ? tableHeaders?.map((eachHeader, eachIndex) => (
                <th key={eachIndex}>{eachHeader}</th>
              ))
              : tableHeaderBrand?.map((eachHeader, eachIndex) => (
                <th key={eachIndex}>{eachHeader}</th>
              ))}
          </tr>
        </thead>
        <tbody>
          {visitsData?.length ? (
            visitsData?.map((eachVisit, eachIndex) => (
              <tr key={eachIndex}>
                <td>
                  {eachVisit?.name ||
                    eachVisit?.label ||
                    `Visit ${eachIndex + 1}`}
                </td>
                <td>{moment(eachVisit?.addedOn).format("MMM DD")}</td>
                <td>{eachVisit?._addedBy?.name?.full}</td>
                <td>
                  <Button
                    className="actionBtn"
                    onClick={() => navigate(`/view-visit/${eachVisit?._id}`)}
                  >
                    <i className="far fa-eye" />
                  </Button>
                </td>
                <td>
                  <span
                    className={
                      eachVisit?.status === "Declined"
                        ? "statusDeclined"
                        : "statusApproved"
                    }
                  >
                    {eachVisit?.status}
                  </span>
                </td>
                {userData?.role === "admin" ? (
                  <td>
                    <FormGroup switch>
                      <Input
                        type="switch"
                        checked={eachVisit?.isVisibleForBrand}
                        disabled={eachVisit?.status !== "Approved"}
                        onClick={(e) => _updateVisit(eachVisit?._id, e.target.checked)}
                      />
                      <Label
                        check
                        className="mb-0 cursorPointer"
                        style={{ marginTop: 2 }}
                      >
                        Show to Brand
                      </Label>
                    </FormGroup>
                  </td>
                ) : null}
              </tr>
            ))
          ) : (
            <tr>
              <td
                colSpan={userData?.role === "admin" ? 6 : 6}
                className="text-center"
              >
                No Visits Yet
              </td>
            </tr>
          )}
        </tbody>
      </Table>

      <AddVisitDetailsModal
        isOpen={modalState.isAddEditOpen}
        data={modalState.data}
        index={modalState.index}
        toggle={_handleModalToggle}
        visitsData={visitsData}
        shopId={shopId}
        _reloadData={_reloadData}
      />
      <CarouselModal
        isOpen={modalState.isViewPicturesOpen}
        toggle={_handleModalToggle}
        imageArray={modalState.data?.sharedPhotos}
        modalHeading={`${modalState?.data?.label || `Visit ${modalState?.index + 1}`
          }`}
      />
    </React.Fragment>
  );
};

export default VisitsComponent;
