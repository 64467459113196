import React, { useState } from "react";
import {
  Modal,
  ModalFooter,
  ModalHeader,
  Table,
  Button,
  ModalBody,
} from "reactstrap";
import { handleApproveRejectBid } from "../../http/http-calls";
import { formatINR, showToaster } from "../../helper-methods";

const BidTableModal = ({
  isOpen,
  toggle,
  data,
  index,
  shopData,
  _handleParentStateUpdate,
  _refetchData,
  userType,
}) => {
  const [isLoading, setIsLoading] = useState(false);

  const _closeModal = () => {
    toggle();
  };

  const _handleBidApproveReject = (bidData, bidIndex, type) => {
    switch (type) {
      case "approve":
        _makeApiCall(bidData?._id, "Approved", bidIndex);
        break;
      case "reject":
        _makeApiCall(bidData?._id, "Declined", bidIndex);
        break;
      default:
        break;
    }
  };

  const _makeApiCall = async (bidId, bidStatus, index) => {
    setIsLoading(true);
    try {
      const response = await handleApproveRejectBid(bidId, {
        status: bidStatus,
      });

      let updatedShopState = shopData?.length ? [...shopData] : [];
      updatedShopState.splice(index, 1, response?.shop);
      _handleParentStateUpdate(updatedShopState);
      setIsLoading(false);

      showToaster("success", "Bid status updated");
      toggle();
    } catch (e) {
      console.log("error accepting rejecting data", e);
      setIsLoading(false);
    }
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>
        Bids Table for {data?.code || "VK001"}
      </ModalHeader>
      <ModalBody>
        <Table responsive>
          <thead>
            <tr>
              <th>Shelves</th>
              <th>Bid Amount</th>
              <th>Months</th>
              {userType === "shop" ? <th>Added By</th> : ""}
              {userType === "shop" ? <th>Action(s)</th> : <th>Status</th>}
            </tr>
          </thead>
          <tbody>
            {data?._bids?.length ? (
              data?._bids?.map((eachBid, eachIndex) => (
                <tr key={eachIndex}>
                  <td>{eachBid?.shelfOnRent}</td>
                  <td>
                    <i className="fas fa-rupee-sign" />
                    {formatINR(
                      eachBid?.bidAmount || data?.avgRentPerShelf || 0
                    )}
                  </td>
                  <td key={eachIndex}>
                    {eachBid?.months}
                  </td>
                  {userType === "shop" ? (
                    <td>{eachBid?._addedBy?.name?.full}</td>
                  ) : (
                    ""
                  )}
                  {userType === "shop" ? (
                    <td>
                      {eachBid?.status === "Approved" ? (
                        <span className="statusApproved">Approved</span>
                      ) : eachBid?.status === "Declined" ? (
                        <span className="statusDeclined">Declined</span>
                      ) : (
                        <React.Fragment>
                          <Button
                            onClick={() =>
                              _handleBidApproveReject(
                                eachBid,
                                eachIndex,
                                "approve"
                              )
                            }
                            disabled={isLoading}
                            color="success"
                            outline
                          >
                            Approve
                          </Button>
                          &nbsp;
                          <Button
                            onClick={() =>
                              _handleBidApproveReject(
                                eachBid,
                                eachIndex,
                                "reject"
                              )
                            }
                            disabled={isLoading}
                            color="danger"
                            outline
                          >
                            Reject
                          </Button>
                        </React.Fragment>
                      )}
                    </td>
                  ) : (
                    // <td>{eachBid?.status}</td>
                    <td>
                      {eachBid?.status === "Approved" ? (
                        <span className="statusApproved">Approved</span>
                      ) : eachBid?.status === "Declined" ? (
                        <span className="statusDeclined">Declined</span>
                      ) : null }
                    </td>
                  )}
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan={5} className="text-center">
                  No Bids Placed Yet
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => _closeModal()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default BidTableModal;
