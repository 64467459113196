import React from "react";
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import moment from "moment";
import { getRandomColor } from "../../helper-methods";

ChartJS.register(ArcElement, Tooltip, Legend);

const ShopDashboardPieChart = ({ pieChartData }) => {
  const _formatBrandsDataForLabels = (brandsArray, type = "label") => {
    let returnArray = [];

    brandsArray?.forEach((eachBrand) => {
      if (type === "label")
        returnArray.push(
          eachBrand?.name?.full || eachBrand?.name?.first || "N/A"
        );
      else returnArray.push(eachBrand?.revenueAmount || 0);
    });

    return returnArray;
  };

  const data = {
    labels: pieChartData?.brands?.length
      ? _formatBrandsDataForLabels(pieChartData?.brands, "label")
      : [],
    datasets: [
      {
        label: "Market Revenue (in INR)",
        data: pieChartData?.brands?.length
          ? _formatBrandsDataForLabels(pieChartData?.brands, "data")
          : [],
        
        borderColor: pieChartData?.brands?.length
          ? pieChartData?.brands?.forEach((_) => getRandomColor())
          : [],
        borderWidth: 1,
        backgroundColor: [
          'rgb(255, 99, 132)',
          'rgb(54, 162, 235)',
          'rgb(255, 205, 86)',
          'rgb(13,110,253)',
          'rgb(25,135,84)',
          'rgb(13,202,240)',
          'rgb(171, 41, 106)',
          'rgb(220,53,69)',
          'rgb(108,117,125)',
          'rgb(113, 44, 249)',
          'rgb(207, 244, 252)',
          'rgb(102, 77, 3)'
        ],
        hoverOffset: 4,
        offset: 2,
      },
    ],
  };

  return (
    <React.Fragment>
      <div>
        <h3 className="sectionTitle">Revenue Chart for FY {moment().year() || "2024"}</h3>
        {/* <div className="graphWrap pieGraph"> */}
          {/* <div class="chart-container"> */}
            <Pie data={data} className="pieGraph" />
          {/* </div> */}
        {/* </div> */}
      </div>
    </React.Fragment>
  );
};

export default ShopDashboardPieChart;
