import React, { useState, useEffect } from "react";
import { Button, Col, FormGroup, Input, Label, Spinner, Row } from "reactstrap";
import Select from "react-select";
import { useNavigate, useParams } from "react-router-dom";
import { _formatPhotosForCarousal, showToaster } from "../../helper-methods";
import {
  assignShopToMerchendiser,
  fetchAllMerchendiser,
  fetchShopById,
  updateShop,
} from "../../http/http-calls";
import Swal from "sweetalert2";

import ReactstrapCarousel from "../../components/ReactstrapCarousel";
import BidTableCards from "../../components/Cards/BidTableCards";
import VisitsComponent from "../../components/VisitsComponent";
import ShopInfoComponent from "../../components/ShopInfoComponent";
import { useSelector } from "react-redux";
import jwtDecode from "jwt-decode";
import { connectToSocket, newSocket } from "../../socket-io";

const ShopDetailsPage = ({ data }) => {
  const params = useParams();
  const navigate = useNavigate();
  const settingsData = useSelector((state) => state?.settingsData?.settings);
  const reduxDataToken = useSelector((state) => state?.userCredential?.token);
  const userData = jwtDecode(reduxDataToken);

  const [shopData, setShopData] = useState(null);
  const [visitData, setVisitData] = useState([]);
  const [assigneeData, setAssigneeData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [filterData, setFilterData] = useState({
    assignees: [],
    route: "",
  });

  const _formatAssignees = (arrayData) => {
    let returnArray = [];
    arrayData.forEach((each) => returnArray.push({ label: each?.name?.full, value: each?._id }))
    return returnArray
  }

  const _fetchShopData = async (shopId) => {
    setIsLoading(true);
    try {
      const shopDataRes = await fetchShopById(shopId || params.id);
      setShopData(shopDataRes?.shop);
      setVisitData(shopDataRes?.visits || []);
      setFilterData({
        assignees: _formatAssignees(shopDataRes?.shop?._assignees) || [],
        route: shopDataRes.shop?.route || "",
      });

      //subscribe to all channels
      _subscribeToThreadChannel(shopDataRes?.shop?._id);

      setIsLoading(false);
    } catch (e) {
      console.error(e.reason || "Something went wrong");
      setIsLoading(false);
    }
  };

  const _deleteVisitAlert = (visitData, index) => {
    if (!visitData?._id) {
      showToaster("error");
      return;
    }

    Swal.fire({
      title: "Are you sure?",
      text: `You want to delete this visit.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#dd0000",
      // cancelButtonColor: "#f16667",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        let payload = {};

        let localVisits = shopData.visits.filter(
          (each) => each?._id !== visitData?._id
        );

        payload.visits = localVisits?.length ? [...localVisits] : [];
        _makeUpdateApiCall(payload);
      }
    });
  };

  const _makeUpdateApiCall = async (payload) => {
    try {
      let updateRes = await updateShop(shopData?._id, payload);

      showToaster("success", "Removed successfully");
      setShopData(updateRes?.shop);
    } catch (e) {
      showToaster("error", e.reason || "Something went wrong");
    }
  };

  const _handleUpdateAssigneeForShop = async (key, value) => {
    try {
      let updatedFilterData = { ...filterData }
      updatedFilterData[key] = value
      setFilterData(updatedFilterData)

      await assignShopToMerchendiser(params?.id, {
        merchandiserIds: updatedFilterData?.assignees?.map((e) => e.value)
      });
      showToaster("success", "Updated successfully");
      _fetchShopData(params?.id);
    } catch (e) {
      showToaster("error", e.reason);
    }
  };

  const _handleUpdateRouteForShop = async (route) => {
    try {
      console.log("shop data ", shopData);

      await assignShopToMerchendiser(params?.id, {
        route,
      });
      showToaster("success", "Updated successfully");
      _fetchShopData(params?.id);
    } catch (e) {
      showToaster("error", e.reason);
    }
  };

  const _fetchAllMerchendisers = async () => {
    try {
      const merchendiseRes = await fetchAllMerchendiser({
        isActive: true,
        page: 1,
        limit: 10000,
      });
      setAssigneeData(merchendiseRes?.merchandisers);
    } catch (e) {
      console.error(e.reason);
    }
  };

  const _formatListForMultiAssignee = (arrayOfObjects) => {
    let returnArray = [];
    arrayOfObjects?.forEach((each) => returnArray?.push({ label: each?.name?.full, value: each?._id }))
    return returnArray
  }

  useEffect(() => {
    if (params?.id) _fetchShopData(params?.id);

    _fetchAllMerchendisers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params]);

  const _subscribeToThreadChannel = (shopId) => {
    try {
      const params = { room: `visitData/${shopId}` };
      newSocket.emit("subscribe", params, function (res) {
        console.log("subscribed>>", res);
      });
    } catch (error) {
      showToaster(
        "error",
        error.reason && error.reason.length
          ? error.reason
          : "Server error. Try again after sometime."
      );
    }
  };

  const _listenToSubscribedSocketConnection = () => {
    const userRole = userData?.role;

    switch (userRole) {
      case "admin":
        // eslint-disable-next-line no-lone-blocks
        {
          newSocket.on("visitAdded", (receiveMessage) => {
            console.log("received>>", receiveMessage);

            // showToaster("info", "A new visit is added");

            _fetchShopData(params?._id);
          });
        }
        break;
      case "Brand":
        // eslint-disable-next-line no-lone-blocks
        {
          newSocket.on("visitUpdated", (receiveMessage) => {
            console.log("received>>", receiveMessage);

            // showToaster("info", "A new visit is added");

            _fetchShopData(params?._id);
          });
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    _listenToSubscribedSocketConnection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSocket]);

  useEffect(() => {
    connectToSocket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle justify-content-start">
          <Button className="backBtn" onClick={() => navigate(-1)} outline>
            <i className="fas fa-chevron-left" />
          </Button>
          <h2>Shop Details</h2>
        </div>
      </Col>

      {isLoading ? (
        <Col xs="12">
          <Spinner className="spinnerInTable" />
        </Col>
      ) : (
        <React.Fragment>
          <Col xs="12" md="6">
            <div className="carousel-component shopDetailsCarousel">
              <ReactstrapCarousel
                itemsArray={_formatPhotosForCarousal(shopData?.sharedPhotos)}
              />
            </div>
          </Col>

          <Col xs="12" md="6">
            {userData?.role === "admin" ? (
              <Row className="mb-2">
                <Col xs={6}>
                  <FormGroup>
                    <Label>Assignee</Label>
                    <Select
                    placeholder="Select"
                    classNamePrefix="select"
                    isMulti={true}
                    menuShouldScrollIntoView={true}
                    isSearchable={false}
                    name="assignee"
                    options={_formatListForMultiAssignee(assigneeData)}
                    value={filterData?.assignees}
                    onChange={(e) => _handleUpdateAssigneeForShop("assignees", e)}
                    disabled={isLoading}
                  />
                    {/* <Input
                      type="select"
                      value={filterData?.assignee}
                      onChange={(e) =>
                        _handleUpdateAssigneeForShop(e.target.value)
                      }
                    >
                      <option value="">Select Assignee</option>
                      {assigneeData?.length ? (
                        assigneeData?.map((each, index) => (
                          <option key={index} value={each?._id}>
                            {each?.name?.full ||
                              each?.name?.first ||
                              each?.name?.last ||
                              "--"}
                          </option>
                        ))
                      ) : (
                        <option disabled>No Assignee(s) Added</option>
                      )}
                    </Input> */}
                  </FormGroup>
                </Col>

                <Col xs={6}>
                  <FormGroup>
                    <Label>Route</Label>
                    <Input
                      type="select"
                      value={filterData?.route}
                      onChange={(e) => _handleUpdateRouteForShop(e.target.value)}
                    >
                      <option value="">Select Route</option>
                      {settingsData?.routes?.length ? (
                        settingsData?.routes?.map((each, index) => (
                          <option key={index} value={each?._id}>
                            {each || "--"}
                          </option>
                        ))
                      ) : (
                        <option disabled>No Route(s) Added</option>
                      )}
                    </Input>
                  </FormGroup>
                </Col>
              </Row>
            ) : null}

            <ShopInfoComponent shopData={shopData} />
          </Col>

          {userData?.role === "admin" || userData?.role === "Brand" ? (
            <Col xs="12">
              <VisitsComponent
                shopId={shopData?._id}
                visitsData={visitData?.length ? [...visitData] : []}
                _reloadData={_fetchShopData}
                _deleteVisitAlert={_deleteVisitAlert}
              />
            </Col>
          ) : null}

          <Col xs="12">
            <BidTableCards
              tableData={shopData?._bids || []}
              _reload={_fetchShopData}
            />
          </Col>
        </React.Fragment>
      )}
    </>
  );
};

export default ShopDetailsPage;
