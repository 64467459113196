import React from "react";
import { formatINR } from "../../helper-methods";

const ShopDashboardAmountCard = ({ renderType, renderData }) => {
  const _handleDataRenderBasedOnType = (renderType) => {
    switch (renderType) {
      case "totalRevenue":
        return (
          <div className="dashboardCard">
            <div>
              <p>Total Revenue</p>
              <h3 className="colorOrange">
                <i className="fas fa-rupee-sign" />{formatINR(renderData || 0)}
              </h3>
            </div>

            <div className="dashboardCardIcon bgOrange">
              <img src={require("../../assets/img/rupees.png")} alt="Rupee" />
            </div>
          </div>
        );

      case "totalBids":
        return (
          <div className="dashboardCard">
            <div>
              <p>Number of Bids</p>
              <h3 className="colorGreen">{renderData || 0}</h3>
            </div>

            <div className="dashboardCardIcon bgGreen">
              <img src={require("../../assets/img/bids.png")} alt="Bids" style={{marginLeft: -12.5}} />
            </div>
          </div>
        );
      case "shelvesOwned":
        return (
          <div className="dashboardCard">
            <div>
              <p>Number of Shelves Owned</p>
              <h3 className="colorOrange">{renderData || 0}</h3>
            </div>

            <div className="dashboardCardIcon bgOrange">
              <img
                src={require("../../assets/img/shelves.png")}
                alt="Shelves"
                style={{marginLeft: -12.5}}
              />
            </div>
          </div>
        );
      case "approvedBids":
        return (
          <div className="dashboardCard">
            <div>
              <p>Total Approved Bids</p>
              <h3 className="colorGreen">{renderData || 0}</h3>
            </div>

            <div className="dashboardCardIcon bgGreen">
              <img
                src={require("../../assets/img/bids-received.png")}
                alt="Bids Received"
              />
            </div>
          </div>
        );
      default:
        break;
    }
  };

  return (
    <React.Fragment>{_handleDataRenderBasedOnType(renderType)}</React.Fragment>
  );
};

export default ShopDashboardAmountCard;
