import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
  Spinner,
} from "reactstrap";
// import CreatableSelect from "react-select/creatable";
import { RegexConfig } from "../../config/RegexConfig";
import { createBrand, updateBrand } from "../../http/http-calls";
import {
  _formatListForMultiSelect,
  showToaster,
  uploadFilesToCloudinary,
} from "../../helper-methods";
import Select from "react-select";
import { citiesAndStateEnum } from "../../config/helper-config";
import Default from "../../assets/img/default.png";
import { useSelector } from "react-redux";

const AddEditBrandModal = ({
  isOpen,
  data,
  index,
  toggle,
  lastCode,
  _refetchData,
}) => {
  const settingsData = useSelector((state) => state?.settingsData?.settings);
  const [localState, setLocalState] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
    gstNumber: "",
    lineOne: "",
    town: "",
    city: "",
    state: "",
    country: "India",
    zipCode: "",
    companyDetails: "",
    spocName: "",
    spocNumber: "",
    brandLogo: "",
    password: ""
  });
  const [stockState, setStockState] = useState([
    {
      productCode: "",
      productName: "",
      productImage: ""
    }
  ])
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isModalLoading, setIsModalLoading] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const _closeModal = () => {
    _reset();
    toggle();
  };

  const _handleLocalStateUpdate = (type, index = null) => {
    let updatedLocalState = [...stockState];
    console.log("In here 1 :>> ", updatedLocalState, index)
    switch (type) {
      case "add":
        updatedLocalState?.push({ productCode: "", productName: "", productImage: "" });
        break;
      case "delete":
        updatedLocalState?.splice(index, 1);
        break;
      default:
        break;
    }
    console.log("In here 2 :>> ", updatedLocalState)
    setStockState(updatedLocalState);
  };

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let newLocalState = { ...localState };
    let newIsDirty = { ...isDirty };
    newLocalState[name] = value;
    newIsDirty[name] = true;

    setLocalState(newLocalState);
    setIsDirty(newIsDirty);

    _validateState(newLocalState, newIsDirty);
  };

  const _handleStockChange = (key, value, index) => {
    let updatedLocalState = stockState?.length ? [...stockState] : [];

    updatedLocalState[index][key] = value;
    setStockState(updatedLocalState);
  };

  // const _formatMultiSelectValue = (array) => {
  //   let returnArray = [];
  //   array?.forEach((eachElement, eachIndex) =>
  //     returnArray?.push({ label: eachElement, value: eachIndex })
  //   );
  //   return returnArray;
  // };

  // const _renderArrayOfStrings = (arrayOfObjects) => {
  //   let returnArray = [];
  //   arrayOfObjects?.forEach((each) => returnArray?.push(each?.label));
  //   return returnArray;
  // };

  const _handleMultiselectInput = (key, value) => {
    let updatedModalState = { ...localState };
    let newIsDirty = { ...isDirty };
    updatedModalState[key] = value.value !== "" ? value.value || value.key : "";
    newIsDirty[key] = true;
    setLocalState(updatedModalState);
    setIsDirty(newIsDirty);

    _validateState(updatedModalState, newIsDirty);
  };

  const _handleImageUplaod = async (event) => {
    setIsModalLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);
    setLocalState({ ...localState, brandLogo: uploadReturnResponse[0] });
    setIsModalLoading(false);
  };

  const _removePhotoHandler = () => {
    setLocalState({ ...localState, brandLogo: "" });
  };

  const _handleStockImageUplaod = async (key, event, index) => {
    setIsModalLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);
    let updatedLocalState = stockState?.length ? [...stockState] : [];
    updatedLocalState[index][key] = uploadReturnResponse[0];
    setStockState(updatedLocalState);
    setIsModalLoading(false);
  }

  const _removeStockPhotoHandler = (index) => {
    let updatedLocalState = stockState?.length ? [...stockState] : [];
    updatedLocalState[index]["productImage"] = "";
    setStockState(updatedLocalState);
  }

  const _validateState = (newLocalState, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newLocalState).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "email": {
              if (newLocalState[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newLocalState[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Please enter a valid email id";
                  isFormValid = false;
                }
              } else {
                newErrors[key] = "*Please enter a valid email id";
                isFormValid = false;
              }
              break;
            }
            case "name": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "spocName": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (newLocalState[key]?.length) {
                if (newLocalState[key]?.length !== 10) {
                  newErrors[key] = "*Invalid contact info";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "spocNumber": {
              if (newLocalState[key]?.length) {
                if (newLocalState[key]?.length !== 10) {
                  newErrors[key] = "*Invalid contact info";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "lineOne": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "town": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "city": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "state": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "country": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "zipCode": {
              if (newLocalState[key]?.length) {
                if (newLocalState[key]?.length !== 6) {
                  newErrors[key] = "*Invalid zipcode";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "description": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "companyDetails": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "brandLogo": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "password": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Please enter a valid password";
                isFormValid = false;
              }
              break;
            }
            default:
              break;
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onSaveDetails = async () => {
    let newIsDirty = {
      name: true,
      email: true,
      phone: false,
      description: false,
      gstNumber: true,
      lineOne: true,
      town: false,
      city: false,
      state: false,
      country: true,
      zipCode: false,
      companyDetails: false,
      spocName: true,
      spocNumber: true,
      brandLogo: false,
      password: false
    };

    const isFormValid = await _validateState(localState, newIsDirty);
    if (isFormValid) {
      // setIsModalLoading(true);

      let payload = {
        ...localState,
        name: {
          first: localState.name,
        },
        gstDetails: {
          identificationNumber: localState.gstNumber,
        },
        gstAddress: {
          lineOne: localState.lineOne,
          town: localState.town,
          city: localState.city,
          state: localState.state,
          country: localState.country,
          zipCode: localState.zipCode,
        },
        stocks: stockState
      };

      delete payload.lineOne;
      delete payload.town;
      delete payload.city;
      delete payload.state;
      delete payload.country;
      delete payload.zipCode;
      delete payload.gstNumber;

      if (data === null) _handleCreateShack(payload);
      else _handleUpdateShack(payload);
    } else {
      showToaster("error", "Required field(s) missing");
    }
  };

  const _handleCreateShack = async (payload) => {
    setIsModalLoading(true);
    try {
      const shackCreateRes = await createBrand(payload);
      console.log("response creating shack", shackCreateRes);
      _closeModal();
      showToaster("success", "Added successfully");

      _refetchData();
      setIsModalLoading(false);
    } catch (e) {
      console.log("error creating shack", e);
      showToaster("error", e.reason);
      setIsModalLoading(false);
    }
  };

  const _handleUpdateShack = async (payload) => {
    setIsModalLoading(true);
    try {
      const shackUpdateRes = await updateBrand(data?.id, payload);
      console.log("response updating shack", shackUpdateRes);
      _closeModal();

      showToaster("success", "Updated successfully");

      _refetchData();
      setIsModalLoading(false);
    } catch (e) {
      console.log("error updating shack", e);
      setIsModalLoading(false);
    }
  };

  const _reset = () => {
    setIsModalLoading(false);
    setErrors({});
    setIsDirty({});
    setLocalState({
      name: "",
      email: "",
      phone: "",
      description: "",
      gstNumber: "",
      lineOne: "",
      town: "",
      city: "",
      state: "",
      country: "India",
      zipCode: "",
      companyDetails: "",
      spocName: "",
      spocNumber: "",
      brandLogo: "",
      password: ""
    });
  };

  const _formatModalData = (data) => {
    setLocalState((prev) => {
      let newLocalState = { ...localState };

      newLocalState["name"] = data?.name?.first || prev.name || "";
      newLocalState["phone"] = data?.phone || prev.phone || "";
      newLocalState["email"] = data?.email || prev.email || "";
      newLocalState["description"] =
        data?.description || prev.description || "";
      newLocalState["gstNumber"] =
        data?.gstDetails?.identificationNumber || prev.gstNumber || "";
      newLocalState["lineOne"] =
        data?.gstAddress?.lineOne || prev.lineOne || "";
      newLocalState["town"] = data?.gstAddress?.town || prev.town || "";
      newLocalState["city"] = data?.gstAddress?.city || prev.city || "";
      newLocalState["state"] = data?.gstAddress?.state || prev.state || "";
      newLocalState["country"] =
        data?.gstAddress?.country || prev.country || "India";
      newLocalState["zipCode"] =
        data?.gstAddress?.zipCode || prev.zipCode || "";
      newLocalState["companyDetails"] =
        data?.companyDetails || prev.companyDetails || "";
      newLocalState["spocName"] = data?.spocName || prev.spocName || "";
      newLocalState["spocNumber"] = data?.spocNumber || prev.spocNumber || "";
      newLocalState["brandLogo"] = data?.brandLogo || prev.brandLogo || "";
      newLocalState["password"] = "";
      return newLocalState;
    });

    setStockState((prev) => {
      let newLocalState = { ...stockState };
      newLocalState = data?.stocks || prev.stocks || [];
      return newLocalState;
    })
  };

  useEffect(() => {
    if (data !== null) _formatModalData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data !== null ? "Update" : "Create"}
      </ModalHeader>
      <ModalBody className="addBrandModal">
        <h6>Basic Details</h6>

        <Row>
          <Col md={12} className="text-center mb-4">
            <Label className="mb-0">Brand Logo</Label>
            <small className="text-secondary d-block" style={{ fontSize: 12 }}>
              (Picture Should be in the standard format PNG, JPG and less than
              5MB)
            </small>

            <Label className="custom-file-upload d-inline-block mx-auto mt-1 mb-0">
              <Input
                type="file"
                className="d-none"
                onChange={(event) => _handleImageUplaod(event)}
              />
              <div className="productImg">
                <img
                  src={localState?.brandLogo || Default}
                  alt="Product"
                />
                {/* <Button color="link" onClick={() => _removePhotoHandler()}>
                  <i className="fa fa-times" />
                </Button> */}
              </div>
            </Label>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Brand Name</Label>
              <Input
                placeholder="Enter name"
                name="name"
                value={localState.name}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.name ? (
                <div className="form-error">{errors.name}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                placeholder="Enter"
                value={localState.email}
                name="email"
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.email ? (
                <div className="form-error">{errors.email}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Phone</Label>
              <Input
                placeholder="Enter"
                type="number"
                value={localState.phone}
                name="phone"
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.phone ? (
                <div className="form-error">{errors.phone}</div>
              ) : null}
            </FormGroup>
          </Col>
          {data !== null ? (
            ""
          ) : (
            <Col xs={6}>
              <FormGroup>
                <Label>Password</Label>
                <InputGroup>
                  <Input
                    placeholder="Enter"
                    type={`${showPassword ? "text" : "password"}`}
                    value={localState.password}
                    name="password"
                    onChange={(e) => _handleChange(e)}
                    disabled={isModalLoading}
                  />
                  <InputGroupText
                    className="cursorPointer"
                    onClick={() => {
                      setShowPassword(!showPassword);
                    }}
                  >
                    <i
                      className={`far ${showPassword ? "fa-eye" : "fa-eye-slash"
                        }`}
                    ></i>
                  </InputGroupText>
                </InputGroup>
                {isDirty.password ? (
                  <div className="form-error">{errors.password}</div>
                ) : null}
              </FormGroup>
            </Col>
          )}
          <Col md={12}>
            <FormGroup>
              <Label>Description</Label>
              <Input
                placeholder="Enter"
                value={localState.description}
                name="description"
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
                type="textarea"
                rows="3"
              />
              {isDirty.description ? (
                <div className="form-error">{errors.description}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col md={12}>
            <FormGroup>
              <Label>Company Details</Label>
              <Input
                placeholder="Enter"
                name="companyDetails"
                value={localState.companyDetails}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.companyDetails ? (
                <div className="form-error">{errors.companyDetails}</div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <h6>GST Details</h6>
        <Row>
          <Col xs={6}>
            <FormGroup>
              <Label>Identification No.</Label>
              <Input
                placeholder="Enter"
                value={localState.gstNumber}
                name="gstNumber"
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.gstNumber ? (
                <div className="form-error">{errors.gstNumber}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Line One</Label>
              <Input
                placeholder="Line One"
                name="lineOne"
                value={localState.lineOne}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.lineOne ? (
                <div className="form-error">{errors.lineOne}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={12}>
            <FormGroup>
              <Label>Street</Label>
              <Input
                placeholder="Street"
                name="street"
                value={localState.street}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.street ? (
                <div className="form-error">{errors.street}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Town</Label>
              <Select
                placeholder="Select"
                classNamePrefix="select"
                isMulti={false}
                menuShouldScrollIntoView={false}
                isSearchable={true}
                options={_formatListForMultiSelect(settingsData?.locations)}
                value={{ label: localState.town, value: localState.town }}
                onChange={(e) => _handleMultiselectInput("town", e)}
                disabled={isModalLoading}
              />
              {isDirty.town ? (
                <div className="form-error">{errors.town}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>City</Label>
              <Input
                type="select"
                name="city"
                value={localState.city}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              >
                <option value="">Select</option>
                {citiesAndStateEnum?.map((eachEnum, _) => (
                  <option key={eachEnum?.id}>
                    {eachEnum?.name || eachEnum?.id || "N/A"}
                  </option>
                ))}
              </Input>
              {isDirty.city ? (
                <div className="form-error">{errors.city}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={6} md={4}>
            <FormGroup>
              <Label>State</Label>
              <Input
                type="select"
                name="state"
                value={localState.state}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              >
                <option value="">Select</option>
                {citiesAndStateEnum?.map((eachEnum, _) => (
                  <option key={eachEnum?.id}>
                    {eachEnum?.state || eachEnum?.id || "N/A"}
                  </option>
                ))}
              </Input>
              {isDirty.state ? (
                <div className="form-error">{errors.state}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={6} md={4}>
            <FormGroup>
              <Label>Country</Label>
              <Input
                placeholder="Country"
                name="country"
                value={localState.country}
                onChange={(e) => _handleChange(e)}
                disabled={true}
              />
              {isDirty.country ? (
                <div className="form-error">{errors.country}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={6} md={4}>
            <FormGroup>
              <Label>Zip Code</Label>
              <Input
                placeholder="Zip Code"
                name="zipCode"
                value={localState.zipCode}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.zipCode ? (
                <div className="form-error">{errors.zipCode}</div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        <h6>SPOC Details</h6>
        <Row>
          <Col md={6}>
            <FormGroup>
              <Label>Name</Label>
              <Input
                placeholder="Enter POC Name"
                name="spocName"
                value={localState.spocName}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.spocName ? (
                <div className="form-error">{errors.spocName}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col md={6}>
            <FormGroup>
              <Label>Phone</Label>
              <Input
                placeholder="Enter POC Phone"
                name="spocNumber"
                value={localState.spocNumber}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.spocNumber ? (
                <div className="form-error">{errors.spocNumber}</div>
              ) : null}
            </FormGroup>
          </Col>
        </Row>

        {/* <CreatableSelect
          placeholder="Product Name"
          classNamePrefix="select"
          isMulti={true}
          menuShouldScrollIntoView={false}
          isSearchable={true}
          value={_formatMultiSelectValue(localState?.stocks)}
          onChange={(e) => _handleMultiselectInput("stocks", e)}
          name="stock"
          isDisabled={isModalLoading}
        /> */}

        <div className="d-flex justify-content-between align-items-center mt-2">
          <h6 className="my-0">Stock Details</h6>

          <Button
            className="addBtn"
            onClick={() => _handleLocalStateUpdate("add")}
          >
            <i className="fas fa-plus me-1" />
            Add
          </Button>
        </div>

        {stockState?.length
          ? stockState?.map((each, index) => (
            <div className="stockInHandWrap addProductWrap" key={index}>
              {/* if another product gets added then only show this */}
              {index !== 0 || stockState?.length > 1 ? (
                <Button
                  color="link"
                  onClick={() => _handleLocalStateUpdate("delete", index)}
                >
                  <i className="fas fa-times" />
                </Button>
              ) : null}

              <div>
                <FormGroup>
                  <Label>Product Code</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={each?.productCode}
                    onChange={(e) =>
                      _handleStockChange("productCode", e.target.value, index)
                    }
                  />
                </FormGroup>

                <FormGroup>
                  <Label>Product Name</Label>
                  <Input
                    type="text"
                    placeholder="Enter"
                    value={each?.productName}
                    onChange={(e) =>
                      _handleStockChange("productName", e.target.value, index)
                    }
                  />
                </FormGroup>
              </div>

              <div>
                <Label className="d-block mb-0">Product Image</Label>
                <small className="text-secondary" style={{ fontSize: 12 }}>
                  (Picture Should be in the standard format PNG, JPG and less than
                  5MB)
                </small>

                <Label className="custom-file-upload mt-1 mb-0">
                  <Input
                    type="file"
                    className="d-none"
                    onChange={(event) => _handleStockImageUplaod("productImage", event, index)}
                  />
                  <div className="productImg">
                    <img
                      src={each?.productImage || Default}
                      alt="Product"
                    />
                    {/* <Button onClick={() => _removeStockPhotoHandler(index)}>
                      <i className="fa fa-times" />
                    </Button> */}
                  </div>
                </Label>
              </div>
            </div>
          )) : <p className="mt-2">No Product(s)</p>}

      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isModalLoading}
          onClick={() => _onSaveDetails()}
        >
          {isModalLoading ? (
            <Spinner size="sm" />
          ) : data === null ? (
            "Create"
          ) : (
            "Update"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditBrandModal;
