import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import { _formatPhotosForCarousal } from "../../helper-methods";

import ReactstrapCarousel from "../ReactstrapCarousel";

const CarouselModal = ({ isOpen, toggle, imageArray, modalHeading }) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} scrollable centered>
      <ModalHeader toggle={() => toggle()}>{modalHeading}</ModalHeader>
      <ModalBody>
        <div className="carousel-component visitCarouselModal">
          <ReactstrapCarousel
            itemsArray={_formatPhotosForCarousal(imageArray)}
          />
        </div>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default CarouselModal;
