import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  Input,
  InputGroup,
  Spinner,
  Row,
  Col,
  Table,
  UncontrolledCollapse,
  FormGroup,
  UncontrolledTooltip,
} from "reactstrap";
import { fetchAllBrands } from "../../http/http-calls";
import AddEditBrandModal from "../../components/modals/AddEditBrandModal";
import PaginatedItems from "../../components/PaginatedItems";
import {
  deepClone,
  // showToaster,
  capitalize,
  formatDate,
  formatINR,
  copyToClipBoard
} from "../../helper-methods";
import SendEmailModal from "../../components/modals/SendEmailModal";

const BrandsPage = () => {
  const [modalState, setModalState] = useState({
    isAddEditOpen: false,
    isAllowSendEmail: false,
    data: null,
    index: null,
  });
  const [brandData, setBrandData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [filterState, setFilterState] = useState({
    search: "",
    page: 1,
    limit: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);
  const [selectedBrandsForMassEmail, setSelectedBrandsForMassEmail] = useState(
    []
  );

  const _handleIsChecked = (data) => {
    let isChecked = false;
    if (selectedBrandsForMassEmail.length) {
      selectedBrandsForMassEmail.includes(data._id)
        ? (isChecked = true)
        : (isChecked = false);
    } else {
      isChecked = false;
    }

    return isChecked;
  };

  const _handleIsAllSelected = () => {
    let isAllSelected = false;

    const matchedObjectsFilter = brandData?.filter((obj) =>
      selectedBrandsForMassEmail.includes(obj._id)
    );

    if (matchedObjectsFilter?.length === brandData?.length) {
      isAllSelected = true;
    } else {
      isAllSelected = false;
    }
    return isAllSelected;
  };

  const _manageEmailReceipientState = (
    selectType = "All",
    brandId = null,
    checkedState = false
  ) => {
    let updatedSelectedBrandId = selectedBrandsForMassEmail?.length
      ? [...selectedBrandsForMassEmail]
      : [];
    if (brandData?.length) {
      switch (selectType) {
        case "All":
          if (checkedState)
            brandData?.forEach((eachBrand) =>
              updatedSelectedBrandId.push(eachBrand?.id)
            );
          else updatedSelectedBrandId = [];
          break;
        case "One":
          if (checkedState) {
            updatedSelectedBrandId.push(brandId);
          } else {
            updatedSelectedBrandId = updatedSelectedBrandId?.filter(
              (eachBrand) => eachBrand !== brandId
            );
          }
          break;
        default:
          break;
      }
    }

    setSelectedBrandsForMassEmail(updatedSelectedBrandId);
  };

  const _handleFilterChange = (e) => {
    const { name, value } = e.target;
    let newFilters = { ...filterState };
    newFilters[name] = value;

    setFilterState(newFilters);

    if (name === "search") {
      setIsSearchLoading(true);
      optimizedFn(newFilters);
    } else {
      _fetchAllBrands(newFilters);
    }
  };

  const _checkHandler = async (data) => {
    // console.log("check>>", data);
    _fetchAllBrands(data);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  const _handleModalToggle = (
    isAddEditOpen = false,
    isAllowSendEmail = false,
    data = null,
    index = null
  ) => {
    setModalState({ isAddEditOpen, isAllowSendEmail, data, index });
  };

  const _handleClearFilters = () => {
    setFilterState((prev) => {
      let newFilters = { ...prev };
      newFilters["search"] = "";
      newFilters["page"] = 1;
      newFilters["limit"] = 10;

      _fetchAllBrands(newFilters);
      return newFilters;
    });
  };

  const _fetchAllBrands = async (payload) => {
    setIsLoading(true);
    try {
      const brandRes = await fetchAllBrands(payload);
      console.log("response of shops", brandRes);

      setBrandData(brandRes.brands);
      setDataCount(brandRes.totalCount);

      setIsSearchLoading(false);
      setIsLoading(false);
    } catch (e) {
      console.log("error fetching shop data", e);
      setIsSearchLoading(false);
      setIsLoading(false);
    }
  };

  const _onPageChange = (pageNumber) => {
    const newDataPayload = deepClone(filterState);
    newDataPayload["page"] = pageNumber + 1;
    setFilterState(newDataPayload);
    _fetchAllBrands(newDataPayload);
  };

  useEffect(() => {
    if (brandData === null) _fetchAllBrands(filterState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle">
          <h2>Brand</h2>

          <div className="flex-shrink-0 ms-3">
            <Button
              className="addBtn"
              onClick={() => _handleModalToggle(true, false, null, null)}
            >
              <i className="fas fa-plus me-1" />
              Add
            </Button>

            <Button id="toggler" className="mobileFilter">
              <i className="fas fa-filter" />
            </Button>
          </div>
        </div>

        <UncontrolledCollapse toggler="#toggler" isOpen>
          <div className="filterWrapper">
            {/* <i className="fas fa-filter filterIcon" /> */}
            <Row>
              <Col lg={3} md={5} sm={6} xs={9}>
                <FormGroup>
                  {/* <Label className="invisible">Search</Label> */}
                  <InputGroup className="searchTable">
                    <Input
                      type="text"
                      name="search"
                      placeholder="Search"
                      autoComplete="off"
                      value={filterState.search}
                      onChange={(e) => _handleFilterChange(e)}
                    />
                    <Button color="link">
                      {isSearchLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="fa fa-search" />
                      )}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col lg={1} sm={2} xs={3}>
                <div className="mb-3">
                  {/* <Label className="d-block invisible">Reset</Label> */}
                  <Button
                    outline
                    color="warning"
                    onClick={() => _handleClearFilters()}
                    id="clearFilter"
                  >
                    <i className="fa fa-eraser" />
                  </Button>
                  <UncontrolledTooltip placement="bottom" target="clearFilter">
                    Clear Filter
                  </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
          </div>
        </UncontrolledCollapse>
      </Col>

      <Col xs="12">
        {selectedBrandsForMassEmail?.length ? (
          <div className="d-flex" style={{ marginBottom: 10 }}>
            <Button
              className="addBtn"
              onClick={() => _handleModalToggle(false, true)}
            >
              Send Email
            </Button>
          </div>
        ) : null}

        <Table responsive className="checkBoxTable">
          <thead>
            <tr>
              <th>
                <FormGroup check>
                  <Input
                    id="exampleCheck"
                    name="check"
                    type="checkbox"
                    checked={_handleIsAllSelected()}
                    onChange={(event) =>
                      _manageEmailReceipientState(
                        "All",
                        null,
                        event.target.checked
                      )
                    }
                  />
                </FormGroup>
              </th>
              <th>Name</th>
              <th>GST Details</th>
              <th>SPOC</th>
              <th>Shelves Owned</th>
              <th>Revenue</th>
              <th>Created On</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <Spinner className="spinnerInTable" />
                </td>
              </tr>
            ) : (
              brandData?.map((brand, index) => (
                <tr key={`brandList_${brand._id}_${index}`}>
                  <td>
                    <FormGroup check>
                      <Input
                        id="exampleCheck1"
                        name="check"
                        type="checkbox"
                        checked={_handleIsChecked(brand)}
                        onChange={(event) =>
                          _manageEmailReceipientState(
                            "One",
                            brand?.id,
                            event.target.checked
                          )
                        }
                      />
                    </FormGroup>
                  </td>
                  <td>
                    <div className="d-flex">
                      <div className="brandLogo">
                        <img
                          src={
                            brand.brandLogo
                              ? brand.brandLogo
                              : require("../../assets/img/user.png")
                          }
                          alt="Logo"
                        />
                      </div>
                      <div>
                        {capitalize(brand?.name?.first)}
                        <div className="mt-1">
                          {/* Phone  */}
                          <i
                            className="fas fa-phone-alt me-3"
                            style={{ fontSize: 13 }}
                            id={`brandPhone-${index}`}
                            onClick={() =>
                              copyToClipBoard(brand?.phone || "N/A")
                            }
                          />
                          <UncontrolledTooltip
                            target={`brandPhone-${index}`}
                            placement="bottom"
                          >
                            {brand?.phone || "N/A"}
                          </UncontrolledTooltip>

                          {/* Email */}
                          <i
                            className="far fa-envelope"
                            style={{ verticalAlign: -0.5 }}
                            id={`brandEmail-${index}`}
                            onClick={() =>
                              copyToClipBoard(brand?.email || "N/A")
                            }
                          />
                          <UncontrolledTooltip
                            target={`brandEmail-${index}`}
                            placement="bottom"
                          >
                            {brand?.email || "N/A"}
                          </UncontrolledTooltip>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>{brand?.gstDetails?.identificationNumber || "-"}</td>
                  <td>{brand?.spocName || "-"}</td>
                  <td>{brand?.shelvesOwned || "0"}</td>
                  <td>
                    <i className="fas fa-rupee-sign" />
                    {formatINR(brand?.revenueAmount || 0)}
                  </td>
                  <td>{formatDate(brand?.createdAt)}</td>
                  <td>
                    <Button
                      onClick={() =>
                        _handleModalToggle(true, false, brand, index)
                      }
                      className="actionBtn"
                    >
                      <i className="fas fa-pencil-alt" />
                    </Button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Col>

      {brandData?.length && dataCount ? (
        <div className="paginationWrap">
          <div className="tableCount">
            Showing <span>{brandData?.length}</span> entries out of{" "}
            <span>{dataCount}</span>
          </div>
          <PaginatedItems
            forcePage={filterState.page}
            itemsPerPages={brandData?.length}
            totalCount={dataCount}
            selectedPage={filterState.page}
            itemsPerPage={filterState.limit}
            onPageChange={_onPageChange}
          />
        </div>
      ) : null}

      <AddEditBrandModal
        isOpen={modalState.isAddEditOpen}
        data={modalState.data}
        index={modalState.index}
        toggle={_handleModalToggle}
        _refetchData={() => _fetchAllBrands(filterState)}
      />

      <SendEmailModal
        isOpen={modalState.isAllowSendEmail}
        toggle={_handleModalToggle}
        checkedState={selectedBrandsForMassEmail}
        resetCheckedState={() => setSelectedBrandsForMassEmail([])}
        brandData={brandData}
      />
    </>
  );
};

export default BrandsPage;
