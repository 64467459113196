import React from "react";
import {
  CarouselControl,
  Carousel,
  CarouselItem,
} from "reactstrap";

const ReactstrapCarousel = (itemsArray) => {
  // State for Active index
  const [activeIndex, setActiveIndex] = React.useState(0);

  // State for Animation
  const [animating, setAnimating] = React.useState(false);

  // Items array length
  const itemLength = itemsArray?.itemsArray?.length - 1;

  // Previous button for Carousel
  const previousButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? itemLength : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  // Next button for Carousel
  const nextButton = () => {
    if (animating) return;
    const nextIndex = activeIndex === itemLength ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  // Carousel Item Data
  const carouselItemData = [...itemsArray?.itemsArray].map((item) => {
    return (
      <CarouselItem
        key={item.src}
        onExited={() => setAnimating(false)}
        onExiting={() => setAnimating(true)}
      >
        <img src={item.src} alt={item.altText} />
      </CarouselItem>
    );
  });

  return (
    <Carousel
      previous={previousButton}
      next={nextButton}
      activeIndex={activeIndex}
    >
      {/* <CarouselIndicators
        items={itemsArray.itemsArray}
        activeIndex={activeIndex}
        onClickHandler={(newIndex) => {
          if (animating) return;
          setActiveIndex(newIndex);
        }}
      /> */}
      {carouselItemData}
      <CarouselControl
        directionText="Prev"
        direction="prev"
        onClickHandler={previousButton}
      />
      <CarouselControl
        directionText="Next"
        direction="next"
        onClickHandler={nextButton}
      />
    </Carousel>
  );
};

export default ReactstrapCarousel;
