import React, { useState, useEffect } from "react";
import { Col, Row, Spinner } from "reactstrap";
import { fetchBrandDashboardData } from "../../http/http-calls";

// Lazy Imports
// const ShopDashboardAmountCard = lazy(
//   () => "../../components/Cards/ShopDashboardAmountCard"
// );
// const BrandDashboardTabsSection = lazy(
//   () => "../../components/Cards/BrandDashboardTabsSection"
// );

import ShopDashboardAmountCard from "../../components/Cards/ShopDashboardAmountCard";
import BrandDashboardTabsSection from "../../components/Cards/BrandDashboardTabsSection";

const BrandDashboard = (props) => {
  const [dashboardData, setDashboardData] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const _fetchDashboardData = async () => {
    setIsLoading(true);
    try {
      const dashboardRes = await fetchBrandDashboardData();
      setDashboardData(dashboardRes);
      setIsLoading(false);
    } catch (e) {
      console.log("error fetching dashboard data", e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dashboardData === null) _fetchDashboardData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props]);

  return (
    <React.Fragment>
      <Col sm={12}>
        <div className="pgTitle">
          <h2>Dashboard</h2>
        </div>
      </Col>

      {isLoading ? (
        <Spinner className="spinnerInTable" />
      ) : (
        <React.Fragment>
          <Col sm={12}>
            <Row>
              <Col sm={6} xl={3} className="d-flex flex-column">
                <ShopDashboardAmountCard
                  renderType="totalRevenue"
                  renderData={dashboardData?.totalRevenue}
                />
              </Col>
              <Col sm={6} xl={3} className="d-flex flex-column">
                <ShopDashboardAmountCard
                  renderType="totalBids"
                  renderData={dashboardData?.totalBids}
                />
              </Col>
              <Col sm={6} xl={3} className="d-flex flex-column">
                <ShopDashboardAmountCard
                  renderType="shelvesOwned"
                  renderData={dashboardData?.shelvesOwned}
                />
              </Col>
              <Col sm={6} xl={3} className="d-flex flex-column">
                <ShopDashboardAmountCard
                  renderType="approvedBids"
                  renderData={dashboardData?.approvedBids}
                />
              </Col>
            </Row>
          </Col>

          <Col sm={12} className="mt-3">
            <BrandDashboardTabsSection renderData={dashboardData} />
          </Col>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BrandDashboard;
