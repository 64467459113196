import React from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Row,
  Col,
  Input,
  InputGroup,
  InputGroupText,
} from "reactstrap";
import { _formatPhotosForCarousal } from "../../helper-methods";

import ReactstrapCarousel from "../ReactstrapCarousel";

const ViewShopModal = ({ isOpen, data, index, toggle, _refetchData }) => {
  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} scrollable centered>
      <ModalHeader toggle={() => toggle()}>View</ModalHeader>
      <ModalBody>
        <Row>
          <Col xs={12}>
            <div className="carousel-component mb-3">
              <ReactstrapCarousel
                itemsArray={_formatPhotosForCarousal(data?.sharedPhotos)}
              />
            </div>
          </Col>

          <Col xs={12}>
            <FormGroup>
              <Label>Shop Name</Label>
              <Input type="text" value={data?.name || ""} disabled />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Racks Availability</Label>
              <Input type="number" value={data?.noOfShelf || ""} disabled />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Cost Per Rack</Label>
              <InputGroup>
                <InputGroupText>
                  <i className="fas fa-rupee-sign" />
                </InputGroupText>
                <Input
                  type="text"
                  value={data?.avgRentPerShelf || ""}
                  disabled
                />
              </InputGroup>
            </FormGroup>
          </Col>
          <Col xs={12}>
            <FormGroup>
              <Label>Street</Label>
              <Input
                type="text"
                value={data?.address?.lineOne || ""}
                disabled
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Town</Label>
              <Input type="text" value={data?.address?.town || ""} disabled />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>City</Label>
              <Input type="text" value={data?.address?.city || ""} disabled />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>State</Label>
              <Input type="text" value={data?.address?.state || ""} disabled />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Country</Label>
              <Input
                type="text"
                value={data?.address?.zipCode || ""}
                disabled
              />
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Zip Code</Label>
              <input
                type="text"
                className="form-control"
                value={data?.name || ""}
                disabled
              />
            </FormGroup>
          </Col>
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => toggle()}>
          Close
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ViewShopModal;
