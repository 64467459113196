import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
  Spinner,
} from "reactstrap";
import { RegexConfig } from "../../config/RegexConfig";
import { showToaster, uploadFilesToCloudinary } from "../../helper-methods";
import {
  createNewMerchendiser,
  updateMerchendiserById,
} from "../../http/http-calls";
import Default from "../../assets/img/default.png";

const AddEditSalesPerson = ({ isOpen, toggle, data, index, _refetchData }) => {
  const [localState, setLocalState] = useState({
    employeeCode: "",
    firstName: "",
    lastName: "",
    email: "",
    password: "",
    phone: "",
    profilePic: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isModalLoading, setModalLoading] = useState(false);

  const _closeModal = () => {
    _reset();
    toggle();
  };

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let updatedLocalState = { ...localState };
    let newIsDirty = { ...isDirty };

    updatedLocalState[name] = value;
    newIsDirty[name] = true;

    setLocalState(updatedLocalState);
    setIsDirty(newIsDirty);

    _validateFunction(updatedLocalState, newIsDirty);
  };

  const _validateFunction = (newLocalState, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newLocalState).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "employeeCode": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "firstName": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "lastName": {
              if (newLocalState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "profilePic": {
              if (newLocalState[key] !== "") {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "phone": {
              if (newLocalState[key]?.length) {
                if (newLocalState[key]?.length !== 10) {
                  newErrors[key] = "*Invalid contact info";
                  isFormValid = false;
                } else {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                }
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "email": {
              if (newLocalState[key]?.trim().length) {
                if (
                  RegexConfig.email.test(
                    String(newLocalState[key]).toLowerCase()
                  )
                ) {
                  delete newErrors[key];
                  newIsDirty[key] = false;
                } else {
                  newErrors[key] = "*Please enter a valid email id";
                  isFormValid = false;
                }
                break;
              }
              break;
            }

            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _handleImageUplaod = async (event) => {
    setModalLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);

    let updatedLocalState = { ...localState };
    updatedLocalState["profilePic"] = uploadReturnResponse[0];
    setLocalState(updatedLocalState);

    _validateFunction(updatedLocalState, isDirty);

    setModalLoading(false);
  };

  const _formatDataForPayload = (state) => {
    let finalState = { ...state };

    finalState["name"] = { first: state.firstName, last: state.lastName };

    delete finalState["firstName"];
    delete finalState["lastName"];

    return finalState;
  };

  const _onSaveDetails = async () => {
    let newIsDirty = {
      employeeCode: true,
      firstName: true,
      lastName: true,
      email: false,
      password: true,
      phone: true,
      profilePic: false,
    };

    const isFormValid = await _validateFunction(localState, newIsDirty);
    if (isFormValid) {
      setModalLoading(true);

      const formattedPayload = _formatDataForPayload(localState);

      if (data !== null) {
        _makeUpdateCall(formattedPayload);
      } else {
        _makeAddCall(formattedPayload);
      }
    } else {
      showToaster("error", "Required field(s) missing");
    }
  };

  const _makeAddCall = async (payload) => {
    try {
      await createNewMerchendiser(payload);
      showToaster("success", "Created successfully");
      setModalLoading(false);
      _refetchData();
      _closeModal();
    } catch (e) {
      showToaster("error", e.reason);
      setModalLoading(false);
    }
  };

  const _makeUpdateCall = async (payload) => {
    try {
      await updateMerchendiserById(data?._id, payload);
      showToaster("success", "Updated successfully");
      setModalLoading(false);
      _refetchData();
      _closeModal();
    } catch (e) {
      showToaster("error", e.reason);
      setModalLoading(false);
    }
  };

  const _reset = () => {
    setLocalState({
      employeeCode: "",
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      phone: "",
      profilePic: "",
    });
    setIsDirty({});
    setErrors({});
    setModalLoading(false);
  };

  const _preFillData = (data) => {
    setLocalState({
      employeeCode: data?.employeeCode || "",
      firstName: data?.name?.first || "",
      lastName: data?.name?.last || "",
      email: data?.email || "",
      password: data?.password || "",
      phone: data?.phone || "",
      profilePic: data?.profilePic || "",
    });
  };

  useEffect(() => {
    if (data) _preFillData(data);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  console.log("local", localState);

  return (
    <Modal
      isOpen={isOpen}
      toggle={() => _closeModal()}
      size="lg"
      scrollable
      centered
    >
      <ModalHeader toggle={() => _closeModal()}>
        {data !== null ? "Update" : "Add Merchandiser"}
      </ModalHeader>
      <ModalBody>
        <Row>
          <Col md={12} className="text-center mb-3">
            <Label className="mb-0">Profile Picture</Label>
            <small className="text-secondary d-block" style={{ fontSize: 12 }}>
              (Picture Should be in the standard format PNG, JPG and less than
              5MB)
            </small>

            <Label className="custom-file-upload d-inline-block mx-auto mt-1 mb-0">
              <Input
                type="file"
                className="d-none"
                // disabled
                onChange={(event) => _handleImageUplaod(event)}
              />
              <div className="productImg">
                <img src={localState?.profilePic || Default} alt="Product" />
              </div>
            </Label>
          </Col>
          <Col sm={12}>
            <FormGroup>
              <Label>Employee Code</Label>
              <Input
                placeholder="Enter"
                name="employeeCode"
                value={localState.employeeCode}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.employeeCode ? (
                <div className="form-error">{errors.employeeCode}</div>
              ) : null}
            </FormGroup>
          </Col>
          <div className="d-block w-100"></div>

          <Col xs={6}>
            <FormGroup>
              <Label>First Name</Label>
              <Input
                placeholder="Enter"
                name="firstName"
                value={localState.firstName}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.firstName ? (
                <div className="form-error">{errors.firstName}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Last Name</Label>
              <Input
                placeholder="Enter"
                name="lastName"
                value={localState.lastName}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.lastName ? (
                <div className="form-error">{errors.lastName}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col sm={6}>
            <FormGroup>
              <Label>Email</Label>
              <Input
                placeholder="Enter"
                name="email"
                value={localState.email}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.email ? (
                <div className="form-error">{errors.email}</div>
              ) : null}
            </FormGroup>
          </Col>
          <Col xs={6}>
            <FormGroup>
              <Label>Phone</Label>
              <Input
                placeholder="Enter"
                type="number"
                name="phone"
                value={localState.phone}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.phone ? (
                <div className="form-error">{errors.phone}</div>
              ) : null}
            </FormGroup>
          </Col>

          <Col xs={6}>
            <FormGroup>
              <Label>Password</Label>
              <Input
                placeholder="Enter"
                name="password"
                value={localState.password}
                onChange={(e) => _handleChange(e)}
                disabled={isModalLoading}
              />
              {isDirty.password ? (
                <div className="form-error">{errors.password}</div>
              ) : null}
            </FormGroup>
          </Col>

          {/* <Col xs={12}>
            <Label className="fs-16 d-block mb-0">Upload Profile Picture</Label>
            <small className="text-secondary" style={{ fontSize: 12 }}>
              (Picture Should be in the standard format PNG, JPG, BMP, WEBP and
              less than 5MB)
            </small>

            <div className="custom-file-upload-box-gallery">
              <Label className="custom-file-upload">
                <Input
                  type="file"
                  className="d-none"
                  accept="image/png, image/jpeg,  image/bmp, image/webp"
                  onChange={(event) => _handleImageUplaod(event)}
                />
                Add
              </Label>
              {isDirty.profilePic ? (
                <div className="form-error">{errors.profilePic}</div>
              ) : null}

              {localState?.profilePic !== "" ? (
                <div className="custom-file-upload-box coverImg">
                  <img
                    src={
                      localState?.profilePic ||
                      Default
                    }
                    alt="Uploaded"
                  />
                  <Button
                    onClick={() =>
                      setLocalState({ ...localState, profilePic: "" })
                    }
                  >
                    <i className="fa fa-times" />
                  </Button>
                </div>
              ) : null}
            </div>
          </Col> */}
        </Row>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => _closeModal()}>
          Cancel
        </Button>
        <Button
          color="primary"
          disabled={isModalLoading}
          onClick={() => _onSaveDetails()}
        >
          {isModalLoading ? (
            <Spinner size="sm" />
          ) : data === null ? (
            "Add"
          ) : (
            "Update"
          )}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default AddEditSalesPerson;
