import {
  ADD_SETTINGS_DATA,
  UPDATE_SETTINGS_DATA,
  CLEAR_SETTINGS_DATA,
} from "../actions/";

export const settingsDataReducer = (state, action) => {
  let newState = { ...state };
  switch (action.type) {
    case ADD_SETTINGS_DATA: {
      newState = action.payload.credential;
      break;
    }
    case UPDATE_SETTINGS_DATA: {
      newState = action.payload;
      break;
    }
    case CLEAR_SETTINGS_DATA: {
      newState = state;
      break;
    }
    default:
  }
  return newState;
};
