import React from "react";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import {
  _formatPhotosForCarousal,
  _returnFormattedAddress,
  formatINR,
} from "../../helper-methods";
import ReactstrapCarousel from "../ReactstrapCarousel";
import { useNavigate } from "react-router-dom";

const BrandShopCards = ({ shopData, toggle }) => {
  const navigate = useNavigate();

  const isBrandAllowedToViewShopDetails = (shopData) => {
    let isALlowedView = false;

    if (shopData?.investedAmount > 0) isALlowedView = true;

    return isALlowedView;
  };

  return (
    <Row>
      {shopData.length ? (
        shopData?.map((eachCard, eachIndex) => (
          <Col sm={6} lg={4} xl={3} key={eachCard.id}>
            <Card className="shopInfoWrap">
              <div className="position-relative">
                <ReactstrapCarousel
                  itemsArray={_formatPhotosForCarousal(eachCard?.sharedPhotos)}
                  className="shopImg"
                />

                <div className="shopCode">
                  {eachCard?.code || `Shop No:${eachIndex + 1}`}
                </div>
              </div>
              <CardBody>
                <h6>
                  {_returnFormattedAddress(eachCard?.address, "onlyTown")}
                </h6>
                <p>
                  Average Price:{" "}
                  <b>
                    <i className="fas fa-rupee-sign" />
                    {formatINR(eachCard?.avgRentPerShelf || 0)} / unit
                  </b>
                </p>
                <p>
                  Shelves Available:{" "}
                  <b>{eachCard?.noOfShelfAvailable || 0} unit</b>
                </p>
                <p>
                  Average Footfall:{" "}
                  <b>{formatINR(eachCard?.avgFootfall || 0)}</b>
                </p>
              </CardBody>
              <CardFooter>
                <Button
                  className="viewBids me-2"
                  onClick={() => toggle(true, false, eachCard, eachIndex)}
                >
                  Place Bid
                </Button>

                <Button
                  className="btnOutline me-2"
                  onClick={() => toggle(false, true, eachCard, eachIndex)}
                >
                  View Bids
                </Button>

                <div className="d-inline-flex ms-auto">
                  {isBrandAllowedToViewShopDetails(eachCard) ? (
                    <Button
                      onClick={() => navigate(`/shop-details/${eachCard?._id}`)}
                      className="actionBtn"
                    >
                      <i className="far fa-eye" />
                    </Button>
                  ) : null}
                </div>
              </CardFooter>
            </Card>
          </Col>
        ))
      ) : (
        <p>No Data Found</p>
      )}
    </Row>
  );
};

export default BrandShopCards;
