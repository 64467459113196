import React from "react";
import { Button, Card, CardBody, CardFooter, Col, Row } from "reactstrap";
import {
  _formatPhotosForCarousal,
  _returnFormattedAddress,
  formatINR,
} from "../../helper-methods";
import { useNavigate } from "react-router-dom";
import ReactstrapCarousel from "../ReactstrapCarousel";

const ShopCards = ({ shopData, toggle, _deleteShopAlert }) => {
  const navigate = useNavigate();

  return (
    <Row>
      {shopData.length ? (
        shopData?.map((eachCard, eachIndex) => (
          <Col sm={6} lg={4} xl={3} key={eachCard.id}>
            <Card className="shopInfoWrap">
              <div className="position-relative">
                <ReactstrapCarousel
                  itemsArray={_formatPhotosForCarousal(eachCard?.sharedPhotos)}
                  className="shopImg"
                />

                <div className="shopCode">
                  {eachCard?.code || `Shop No:${eachIndex + 1}`}
                </div>
              </div>
              <CardBody>
                <h5>{eachCard?.name || `Shop No:${eachIndex + 1}`}</h5>

                <h6>
                  {_returnFormattedAddress(eachCard?.address, "onlyTown")}
                </h6>
                <p>
                  Average Price:{" "}
                  <b>
                    <i className="fas fa-rupee-sign" />
                    {formatINR(eachCard?.avgRentPerShelf || 0)} / unit
                  </b>
                </p>
                <p>
                  Shelves Available:{" "}
                  <b>{eachCard?.noOfShelfAvailable || 0} unit</b>
                </p>
                <p>
                  Average Footfall:{" "}
                  <b>{formatINR(eachCard?.avgFootfall || 0)}</b>
                </p>
              </CardBody>
              <CardFooter>
                <Button
                  className="viewBids"
                  onClick={() =>
                    toggle(false, false, true, false, eachCard, eachIndex)
                  }
                >
                  View Bids
                </Button>

                <div className="d-inline-flex ms-auto">
                  <Button
                    onClick={() => navigate(`/shop-details/${eachCard?._id}`)}
                    className="actionBtn"
                  >
                    <i className="far fa-eye" />
                  </Button>

                  <Button
                    onClick={() =>
                      toggle(true, false, false, false, eachCard, eachIndex)
                    }
                    className="actionBtn"
                  >
                    <i className="fas fa-pencil-alt" />
                  </Button>
                  <Button
                    className="actionBtn"
                    onClick={() => _deleteShopAlert(eachCard, eachIndex)}
                  >
                    <i className="far fa-trash-alt" />
                  </Button>
                </div>
              </CardFooter>
            </Card>
          </Col>
        ))
      ) : (
        <p>No Data Found</p>
      )}
    </Row>
  );
};

export default ShopCards;
