export const ADD_USER_CREDENTIAL = "ADD_USER_CREDENTIAL";
export const UPDATE_USER_DATA = "UPDATE_USER_DATA";
export const CLEAR_USER_CREDENTIAL = "CLEAR_USER_CREDENTIAL";

// loader data actions
export const SHOW_LOADER = "SHOW_LOADER";
export const HIDE_LOADER = "HIDE_LOADER";

//settings data
export const ADD_SETTINGS_DATA = "ADD_SETTINGS_DATA";
export const UPDATE_SETTINGS_DATA = "UPDATE_SETTINGS_DATA";
export const CLEAR_SETTINGS_DATA = "CLEAR_SETTINGS_DATA";
