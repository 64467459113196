import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale,
} from "chart.js";
import moment from "moment";
import { getRandomColor } from "../../helper-methods";

ChartJS.register(
  LineElement,
  PointElement,
  LinearScale,
  Title,
  Tooltip,
  Legend,
  CategoryScale
);

const ShopDashboardLineChart = ({ lineChartData }) => {
  const _returnDataForLineChart = (shopsArray) => {
    let returnArray = [];

    shopsArray?.forEach((eachShop) =>
      returnArray.push(eachShop?.code || "VK001")
    );

    return returnArray;
  };

  const _formatDataForEachShop = (shopsArray, type = "noOfShelves") => {
    let dataArray = [];

    shopsArray.forEach((eachShop) => {
      if (type === "noOfShelves") dataArray.push(eachShop?.noOfShelf);
      else dataArray.push(eachShop?.noOfShelfAvailable);
    });

    return dataArray;
  };

  const _returnDataSetForLineChart = (shopsArray) => {
    let returnArray = [];

    returnArray.push({
      label: "Number Of Shelves",
      data: _formatDataForEachShop(shopsArray, "noOfShelves"), // Y-axis data for the first line
      borderColor: getRandomColor(),
      backgroundColor: getRandomColor(),
      tension: 0.4, // Smooth line
    });

    returnArray.push({
      label: "Number Of Available Shelves",
      data: _formatDataForEachShop(shopsArray, "noOfAvailableShelves"), // Y-axis data for the first line
      borderColor: getRandomColor(),
      backgroundColor: getRandomColor(),
      tension: 0.4, // Smooth line
    });

    return returnArray;
  };

  const data = {
    labels: lineChartData?.shops?.length
      ? _returnDataForLineChart(lineChartData?.shops)
      : [], // X-axis labels
    datasets: lineChartData?.shops?.length
      ? _returnDataSetForLineChart(lineChartData?.shops)
      : [],
  };

  const options = {
    responsive: true,
    plugins: {
      legend: {
        position: "top",
      },
      title: {
        display: false,
        text: ``,
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: "Months",
          font: {
            family: "Nunito",
            size: 14,
            weight: "bold",
          },
        },
        fill: false,
    borderColor: 'rgb(75, 192, 192)',
    tension: 0.1
      },
      y: {
        title: {
          display: true,
          text: "Shelves",
          font: {
            family: "Nunito",
            size: 14,
            weight: "bold",
          },
        },
        fill: false,
    borderColor: 'rgb(255, 205, 86)',
    tension: 0.1
      },
    },
  };

  return (
    <React.Fragment>
      <h3 className="sectionTitle">
        Shop Shelves Visualization for FY {`${moment().year() || "2024"}`}
      </h3>

      <div className="graphWrap lineGraph">
        <Line data={data} options={options} />
      </div>
    </React.Fragment>
  );
};

export default ShopDashboardLineChart;
