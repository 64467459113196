import React, { useState, useEffect } from "react";
import { Button, Col, Spinner } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import VisitStepOne from "../../components/VisitStepOne";
import VisitStepTwo from "../../components/VisitStepTwo";
import { showToaster } from "../../helper-methods";
import { fetchShopById } from "../../http/http-calls";

const VisitPage = () => {
  const navigate = useNavigate();
  const params = useParams();

  const [shopData, setShopData] = useState(null);
  const [visitState, setVisitState] = useState({
    id: params?.shopId || "",
    name: "",
    selfieImage: "",
    brandsData: [],
  });
  const [isStepOneComplete, setIsStepOneComplete] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const _handleStateUpdate = (key, value) => {
    if (key === "visit") setVisitState(value);
    else
      setVisitState({
        ...visitState,
        [key]: value,
      });
  };

  // const _formatStocks = (stocks) => {
  //   let updatedStocks = [{
  //     productName: "",
  //     productCost: ""
  //   }]
  //   if (stocks.length > 0) {
  //     updatedStocks = stocks.map((e) => {
  //       const obj = {
  //         productName: e,
  //         productCost: ""
  //       }
  //       return obj
  //     })
  //   }
  //   return updatedStocks
  // }

  const _formatStocks = (stocks) => {
    const updatedStocks = stocks.map((stock) => {
      const obj = {
        productName: stock?.productName,
        productImage: stock?.productImage
      }
      return obj
    })
    return updatedStocks
  }

  const _formatVisitDataWithBrands = (shopRes) => {
    let updatedVisitData = { ...visitState };
    shopRes?._shelfOwners?.forEach((each) =>
      updatedVisitData.brandsData.push({
        brandId: each?._id || "",
        brandName: each?.name?.full || each?.name?.first || "",
        preMerchandisedImage: "",
        postMerchandisedImage: "",
        stockInHand: _formatStocks(each?.stocks),
        newOrders: _formatStocks(each?.stocks),
      })
    );
    // setVisitState(updatedVisitData);
  };

  const _fetchShopData = async (shopId) => {
    setIsLoading(true);
    try {
      const shopRes = await fetchShopById(shopId);
      setShopData(shopRes?.shop);
      _formatVisitDataWithBrands(shopRes?.shop);
      setIsLoading(false);
    } catch (e) {
      showToaster("error", e.reason);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (shopData === null && params?.shopId) _fetchShopData(params.shopId);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <Col sm={12}>
        <div className="pgTitle justify-content-start">
          <Button className="backBtn" onClick={() => navigate(-1)} outline>
            <i className="fas fa-chevron-left" />
          </Button>
          <h2>Visit</h2>
        </div>
      </Col>
      {isLoading ? (
        <Spinner />
      ) : (
        <React.Fragment>
          {/* step 1. After it gets completed, show step 2 */}
          <VisitStepOne
            visitState={visitState}
            _onUpdate={_handleStateUpdate}
            isStepOneComplete={isStepOneComplete}
            _updateStepOneStateToComplete={setIsStepOneComplete}
          />

          {/* step 2, show this only after step 1 gets completed */}
          {visitState?.selfieImage !== "" &&
            visitState?.name !== "" &&
            isStepOneComplete ? (
            <VisitStepTwo
              visitState={visitState}
              _onUpdate={_handleStateUpdate}
            />
          ) : null}
        </React.Fragment>
      )}
    </>
  );
};

export default VisitPage;
