import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Label,
  Input,
  Spinner,
} from "reactstrap";
import { showToaster } from "../../helper-methods";
import { massEmailToBrands } from "../../http/http-calls";

const SendEmailModal = ({
  isOpen,
  toggle,
  checkedState,
  resetCheckedState,
  brandData,
}) => {
  const [mailData, setMailData] = useState({
    subject: "",
    content: "",
  });
  const [isDirty, setIsDirty] = useState({});
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const _renderRecepientList = (checkedStateData, brand) => {
    let returnString = [];
    brand?.forEach((eachBrand) => {
      if (checkedStateData.includes(eachBrand?.id))
        returnString.push(
          eachBrand.email || eachBrand?.name?.full || eachBrand?.name?.first
        );
    });

    return returnString.join(", ");
  };

  const _handleChange = (event) => {
    const { name, value } = event.target;
    let newMailState = { ...mailData };
    let newIsDirty = { ...isDirty };
    newMailState[name] = value;
    newIsDirty[name] = true;

    setMailData(newMailState);
    setIsDirty(newIsDirty);

    _validate(newMailState, newIsDirty);
  };

  const _validate = (newMailState, newIsDirty) => {
    return new Promise((resolve) => {
      const newErrors = { ...errors };
      let isFormValid = true;

      Object.keys(newMailState).forEach((key) => {
        if (newIsDirty[key]) {
          switch (key) {
            case "subject": {
              if (newMailState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            case "content": {
              if (newMailState[key]?.length) {
                delete newErrors[key];
                newIsDirty[key] = false;
              } else {
                newErrors[key] = "*Required";
                isFormValid = false;
              }
              break;
            }
            default:
          }
        }
      });

      setErrors(newErrors);
      setIsDirty(newIsDirty);

      resolve(isFormValid);
    });
  };

  const _onSaveDetails = async () => {
    let newIsDirty = {
      subject: true,
      content: true,
    };

    const isFormValid = await _validate(mailData, newIsDirty);
    if (isFormValid) {
      // setIsLoading(true);

      let payload = {
        ...mailData,
        to: checkedState,
      };
      _sendMassEmail(payload);
    } else {
      showToaster("error", "Required field(s) missing");
    }
  };

  const _sendMassEmail = async (payload) => {
    setIsLoading(true);
    try {
      await massEmailToBrands(payload);
      showToaster("success", "Sent successfully");
      _resetData();
      toggle();
    } catch (e) {
      showToaster("error", e.reason || "Something went wrong");
      setIsLoading(false);
    }
  };

  const _resetData = () => {
    setMailData({
      subject: "",
      content: "",
    });
    setErrors({});
    setIsDirty({});
    setIsLoading(false);
    resetCheckedState();
  };

  return (
    <Modal isOpen={isOpen} toggle={() => toggle()} scrollable centered>
      <ModalHeader toggle={() => toggle()}>Send Email</ModalHeader>
      <ModalBody>
        <FormGroup>
          <Label>To</Label>
          <Input
            type="text"
            placeholder="Recipient(s) here"
            value={_renderRecepientList(checkedState, brandData)}
            disabled
          />
        </FormGroup>
        <FormGroup>
          <Label>Subject</Label>
          <Input
            type="text"
            placeholder="Email subject"
            name="subject"
            value={mailData?.subject}
            onChange={(e) => _handleChange(e)}
          />
          {/* show this when you get any error */}
          {isDirty.subject ? (
            <div className="form-error">{errors.subject}</div>
          ) : null}
        </FormGroup>
        <FormGroup>
          <Label>Body</Label>
          <Input
            type="textarea"
            rows="4"
            placeholder="Email content"
            name="content"
            value={mailData?.content}
            onChange={(e) => _handleChange(e)}
          />
          {/* show this when you get any error */}
          {isDirty.content ? (
            <div className="form-error">{errors.content}</div>
          ) : null}
        </FormGroup>
      </ModalBody>
      <ModalFooter>
        <Button className="closeBtn" onClick={() => toggle()}>
          Cancel
        </Button>
        <Button
          color="primary"
          onClick={() => _onSaveDetails()}
          disabled={isLoading}
        >
          {isLoading ? <Spinner size="sm" /> : "Send"}
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default SendEmailModal;
