import React, { useEffect, useState } from "react";
import ReactPaginate from "react-paginate";

const PaginatedItems = ({
  itemsPerPage,
  totalCount,
  selectedPage,
  onPageChange,
  itemsPerPages,
}) => {
  // eslint-disable-next-line
  const [pageCount, setPageCount] = useState(0);

  useEffect(() => {
    // Fetch items from another resources.

    setPageCount(Math.ceil(totalCount / itemsPerPage));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemsPerPage, totalCount]);

  // Invoke when user click to request another page.
  const _handlePageClick = (event) => {
    onPageChange(event.selected);
  };

  return (
    <ReactPaginate
      forcePage={selectedPage - 1}
      breakLabel="..."
      nextLabel=">"
      onPageChange={(e) => _handlePageClick(e)}
      pageRangeDisplayed={5}
      pageCount={pageCount}
      previousLabel="<"
      renderOnZeroPageCount={null}
      className="pagination"
    />
  );
};

export default PaginatedItems;
