import React, { useEffect, useState, useCallback } from "react";
import {
  Button,
  FormGroup,
  Input,
  InputGroup,
  Label,
  Spinner,
  UncontrolledTooltip,
  Col,
  UncontrolledCollapse,
  Row,
  Table,
} from "reactstrap";

import { fetchMerchandiserShops } from "../../http/http-calls";
import Select from "react-select";
import { visitStatusEnum } from "../../config/helper-config";
import {
  _formatListForMultiSelect,
  deepClone,
  capitalize,
  showToaster,
} from "../../helper-methods";

import PaginatedItems from "../../components/PaginatedItems";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { connectToSocket, newSocket } from "../../socket-io";

const MerchandiserShopPage = () => {
  const navigate = useNavigate();
  const settingsData = useSelector((state) => state?.settingsData?.settings);
  const [modalState, setModalState] = useState({
    isAddVisitOpen: false,
    isShowFilter: false,
    data: null,
    index: null,
  });
  const [shopData, setShopData] = useState(null);
  const [dataCount, setDataCount] = useState(0);
  const [filterState, setFilterState] = useState({
    town: "",
    route: "",
    status: "",
    search: "",
    page: 1,
    limit: 10,
  });
  const [isLoading, setIsLoading] = useState(false);
  const [isSearchLoading, setIsSearchLoading] = useState(false);

  const _handleFilterChange = (e) => {
    const { name, value } = e.target;
    let newFilters = { ...filterState };
    newFilters[name] = value;
    setFilterState(newFilters);

    if (name === "search") {
      setIsSearchLoading(true);
      optimizedFn(newFilters);
    } else {
      _fetchAllShopData(newFilters);
    }
  };

  const _handleMultiselectFilter = (key, value) => {
    let newFilters = { ...filterState };
    newFilters[key] = value.value;

    console.log("test", newFilters);
    setFilterState(newFilters);

    _fetchAllShopData(newFilters);
  };

  const _onPageChange = (pageNumber) => {
    const newDataPayload = deepClone(filterState);
    newDataPayload["page"] = pageNumber + 1;
    setFilterState(newDataPayload);
    _fetchAllShopData(newDataPayload);
  };

  const debounce = (func) => {
    let timer;
    return function (...args) {
      const context = this;
      if (timer) clearTimeout(timer);
      timer = setTimeout(() => {
        timer = null;
        func.apply(context, args);
      }, 500);
    };
  };

  const _checkHandler = async (data) => {
    // console.log("check>>", data);
    _fetchAllShopData(data);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const optimizedFn = useCallback(debounce(_checkHandler), []);

  // const _handleModalToggle = (
  //   isAddVisitOpen = false,
  //   isShowFilter = false,
  //   data = null,
  //   index = null
  // ) => {
  //   setModalState({
  //     isAddVisitOpen,
  //     isShowFilter,
  //     data,
  //     index,
  //   });
  // };

  const _fetchAllShopData = async (payload) => {
    let upadtedPayload = {
      ...payload,
    };
    setIsLoading(true);
    try {
      const shopRes = await fetchMerchandiserShops(upadtedPayload);
      console.log("response of shops", shopRes);
      setShopData(shopRes.shops);
      setDataCount(shopRes.totalCount);

      _subscribeToThreadChannel(shopRes?.shops);
      setIsSearchLoading(false);
      setIsLoading(false);
    } catch (e) {
      console.log("error fetching shop data", e);
      setIsSearchLoading(false);
      setIsLoading(false);
    }
  };

  const _handleClearFilters = () => {
    setFilterState((prev) => {
      let newFilters = { ...prev };
      newFilters["town"] = "";
      newFilters["route"] = "";
      newFilters["status"] = "";
      newFilters["search"] = "";
      newFilters["page"] = 1;
      newFilters["limit"] = 10;

      _fetchAllShopData(newFilters);
      return newFilters;
    });
  };

  useEffect(() => {
    if (shopData === null) _fetchAllShopData(filterState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const _subscribeToThreadChannel = (shopsArray) => {
    if (shopsArray?.length) {
      shopsArray?.forEach((eachShop) => {
        try {
          const params = { room: `visitData/${eachShop?._id}` };
          newSocket.emit("subscribe", params, function (res) {
            console.log("subscribed>>", res);
          });
        } catch (error) {
          showToaster(
            "error",
            error.reason && error.reason.length
              ? error.reason
              : "Server error. Try again after sometime."
          );
        }
      });
    }
  };

  const _listenToSubscribedSocketConnection = () => {
    newSocket.on("visitUpdated", (receiveMessage) => {
      console.log("received>>", receiveMessage);

      // showToaster(
      //   receiveMessage?.data?.status === "Approved" ? "success" : "info",
      //   `${receiveMessage?.data?.name}'s status has been updated to ${receiveMessage?.data?.status}`
      // );

      _fetchAllShopData(filterState);
    });
  };

  useEffect(() => {
    _listenToSubscribedSocketConnection();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newSocket]);

  useEffect(() => {
    connectToSocket();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      <Col sm={12}>
        <div className="pgTitle">
          <h2>Assigned Shops for You</h2>

          <div className="flex-shrink-0 ms-3">
            <Button
              id="toggler"
              className="mobileFilter"
              onClick={() =>
                setModalState({
                  ...modalState,
                  isShowFilter: !modalState.isShowFilter,
                })
              }
            >
              <i className="fas fa-filter" />
            </Button>
          </div>
        </div>
        <UncontrolledCollapse
          toggler="#toggler"
          isOpen
        // isOpen={modalState.isShowFilter}
        >
          <div className="filterWrapper">
            {/* <i className="fas fa-filter filterIcon" /> */}
            <Row>
              <Col lg={3} sm={6} xs={12}>
                <FormGroup>
                  <Label>Town</Label>
                  <Select
                    placeholder="Select"
                    classNamePrefix="select"
                    isMulti={false}
                    menuShouldScrollIntoView={false}
                    isSearchable={true}
                    name="town"
                    options={_formatListForMultiSelect(settingsData?.locations)}
                    value={
                      filterState.town !== ""
                        ? { label: filterState?.town, value: filterState?.town }
                        : "Select"
                    }
                    onChange={(e) => _handleMultiselectFilter("town", e)}
                    disabled={isLoading}
                  />
                </FormGroup>
              </Col>

              <Col lg={3} xs={6}>
                <FormGroup>
                  <Label>Route</Label>
                  <Select
                    placeholder="Select"
                    classNamePrefix="select"
                    isMulti={false}
                    menuShouldScrollIntoView={false}
                    isSearchable={true}
                    name="route"
                    options={_formatListForMultiSelect(settingsData?.routes)}
                    value={
                      filterState.route !== ""
                        ? {
                          label: filterState?.route,
                          value: filterState?.route,
                        }
                        : "Select"
                    }
                    onChange={(e) => _handleMultiselectFilter("route", e)}
                    disabled={isLoading}
                  />
                </FormGroup>
              </Col>

              <Col lg={2} xs={6}>
                <FormGroup>
                  <Label>Status</Label>
                  <Select
                    placeholder="Select"
                    classNamePrefix="select"
                    isMulti={false}
                    menuShouldScrollIntoView={false}
                    isSearchable={true}
                    name="route"
                    options={_formatListForMultiSelect(visitStatusEnum)}
                    value={
                      filterState.status !== ""
                        ? {
                          label: filterState?.status,
                          value: filterState?.status,
                        }
                        : "Select"
                    }
                    onChange={(e) => _handleMultiselectFilter("status", e)}
                    disabled={isLoading}
                  />
                </FormGroup>
              </Col>

              <Col lg={3} sm={4} xs={9}>
                <FormGroup>
                  <Label className="invisible">Search</Label>

                  <InputGroup className="searchTable">
                    <Input
                      type="text"
                      name="search"
                      placeholder="Search"
                      autoComplete="off"
                      value={filterState.search}
                      onChange={(e) => _handleFilterChange(e)}
                    />
                    <Button color="link">
                      {isSearchLoading ? (
                        <Spinner size="sm" />
                      ) : (
                        <i className="fa fa-search" />
                      )}
                    </Button>
                  </InputGroup>
                </FormGroup>
              </Col>

              <Col lg={1} sm={2} xs={3}>
                <div className="mb-3">
                  <Label className="d-block invisible">Reset</Label>
                  <Button
                    outline
                    color="warning"
                    onClick={() => _handleClearFilters()}
                    id="clearFilter"
                    style={{ marginTop: 1 }}
                  >
                    <i className="fa fa-eraser" />
                  </Button>
                  <UncontrolledTooltip placement="bottom" target="clearFilter">
                    Clear Filter
                  </UncontrolledTooltip>
                </div>
              </Col>
            </Row>
          </div>
        </UncontrolledCollapse>
      </Col>

      <Col xs="12">
        <Table responsive className="merchandiseTable">
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Phone</th>
              <th>Address</th>
              <th>Route</th>
              <th>Shelf Available (Total)</th>
              <th>Visit Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={8} className="text-center">
                  <Spinner className="spinnerInTable" />
                </td>
              </tr>
            ) : (
              shopData?.map((shop, index) => (
                <tr key={`shopList_${shop._id}_${index}`}>
                  <td>{capitalize(shop?.code)}</td>
                  <td>{capitalize(shop?.name)}</td>
                  <td>{shop?.phone || "-"}</td>
                  <td>{shop?.address?.town || "-"}</td>
                  <td>{shop?.route || "-"}</td>
                  <td>
                    {shop?.noOfShelfAvailable} ({shop?.noOfShelf})
                  </td>
                  <td>
                    {shop?.isVisitCompletedForWeek === true ? (
                      <span className="statusApproved">Completed</span>
                    ) : (
                      <span className="statusDeclined">Pending</span>
                    )}
                  </td>
                  {shop?._latestVisit && !shop?.isLatestVisitApproved ? (
                    <td>
                      <Button
                        onClick={() => navigate(`/edit-visit/${shop?._latestVisit}`)}
                        className="actionBtn"
                      >
                        <i className="fas fa-pencil-alt" />
                      </Button>
                    </td>
                  ) : (
                    <td>
                      <Button
                        onClick={() => navigate(`/visit/${shop?._id}`)}
                        className="addBtn"
                        disabled={shop?.isVisitCompletedForWeek}
                      >
                        <i className="fas fa-plus" />
                      </Button>
                    </td>
                  )}
                </tr>
              ))
            )}
          </tbody>
        </Table>
      </Col>

      {shopData?.length && dataCount ? (
        <div className="paginationWrap">
          <div className="tableCount">
            Showing <span>{shopData?.length}</span> entries out of{" "}
            <span>{dataCount}</span>
          </div>
          <PaginatedItems
            forcePage={filterState.page}
            itemsPerPages={shopData?.length}
            totalCount={dataCount}
            selectedPage={filterState.page}
            itemsPerPage={filterState.limit}
            onPageChange={_onPageChange}
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

export default MerchandiserShopPage;
