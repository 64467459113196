import React from "react";
import { ListGroup, ListGroupItem } from "reactstrap";
import {
  _returnFormattedAddress,
  _returnOwnedShelvesCountFromBidData,
  decodeToken,
  formatINR,
} from "../helper-methods";
import { useSelector } from "react-redux";

const ShopInfoComponent = ({ shopData }) => {
  const userData = decodeToken(
    useSelector((state) => state?.userCredential?.token)
  );

  const isAllowedToView = (type) => {
    const { role } = userData;

    switch (type) {
      case "code":
        return role === "Brand" ? true : true;
      case "name":
        return role === "Brand" ? false : true;
      case "location":
        return role === "Brand" ? true : true;
      case "phoone":
        return role === "Brand" ? false : true;
      case "footfall":
        return role === "Brand" ? true : true;
      case "availableShelf":
        return role === "Brand" ? true : true;
      case "totalShelf":
        return role === "Brand" ? false : true;
      case "amount":
        return role === "Brand" ? true : false;
      case "ownedShelf":
        return role === "Brand" ? true : false;
      default:
        return false;
    }
  };

  return (
    <React.Fragment>
      <h3 className="sectionTitle mt-0">Shop Info</h3>

      <ListGroup className="shopInfoList">
        {isAllowedToView("code") ? (
          <ListGroupItem>
            Code
            <span>{shopData?.code || "--"}</span>
          </ListGroupItem>
        ) : null}
        {isAllowedToView("name") ? (
          <ListGroupItem>
            Name
            <span>{shopData?.name || "--"}</span>
          </ListGroupItem>
        ) : null}

        {isAllowedToView("phoone") ? (
          <ListGroupItem>
            Phone
            <span>{shopData?.phone || "--"}</span>
          </ListGroupItem>
        ) : null}
        {isAllowedToView("location") ? (
          <ListGroupItem>
            Location
            <span>
              {shopData !== null && shopData !== undefined
                ? _returnFormattedAddress(shopData?.address, "onlyTown")
                : "--"}
            </span>
          </ListGroupItem>
        ) : null}
      
        {isAllowedToView("availableShelf") ? (
          <ListGroupItem>
            No. Of Available Shelves
            <span>{shopData?.noOfShelfAvailable || 0}</span>
          </ListGroupItem>
        ) : null}
        {isAllowedToView("ownedShelf") ? (
          <ListGroupItem>
            No. Of Owned Shelves
            <span>
              {_returnOwnedShelvesCountFromBidData(
                shopData?._bids?.length ? [...shopData?._bids] : []
              )}
            </span>
          </ListGroupItem>
        ) : null}
        {isAllowedToView("footfall") ? (
          <ListGroupItem>
            Avg Footfall
            <span>{formatINR(shopData?.avgFootfall || 0)}</span>
          </ListGroupItem>
        ) : null}
        {isAllowedToView("amount") ? (
          <ListGroupItem>
            Invested Amount
            <span>
              <i className="fas fa-rupee-sign" />{" "}
              {formatINR(shopData?.investedAmount || 0)}
            </span>
          </ListGroupItem>
        ) : null}
        {isAllowedToView("totalShelf") ? (
          <ListGroupItem>
            Total No. Of Shelves <span>{shopData?.noOfShelf || 0}</span>
          </ListGroupItem>
        ) : null}
      </ListGroup>
    </React.Fragment>
  );
};

export default ShopInfoComponent;
