import React, { useState } from "react";
import { Button, Col, FormGroup, Input, Label, Row, Spinner } from "reactstrap";
import { showToaster, uploadFilesToCloudinary } from "../helper-methods";
import Default from "../assets/img/default.png";

const VisitStepOne = ({
  visitState,
  _onUpdate,
  isStepOneComplete,
  _updateStepOneStateToComplete,
}) => {
  const [isComponentLoading, setIsComponentLoading] = useState(false);

  const _handleImageUplaod = async (event) => {
    setIsComponentLoading(true);
    let uploadReturnResponse = await uploadFilesToCloudinary(event);
    _onUpdate("selfieImage", uploadReturnResponse.join(","));
    setIsComponentLoading(false);
  };

  const _handleUpdateStepOneStatus = (status) => {
    _updateStepOneStateToComplete(status);
    showToaster("success", "Attendance Marked");
  };

  return (
    <React.Fragment>
      <Col sm={12}>
        <Row className="visitStepWrap">
          <Col md="6" className="d-flex flex-column">
            <FormGroup className="mb-4">
              <Label>Visit Name</Label>
              <Input
                value={visitState?.name}
                onChange={(e) => _onUpdate("name", e.target.value)}
              />
            </FormGroup>

            <FormGroup className="text-center text-md-start">
              <Label className="d-block">Attendance (Upload Selfie with Outlet Board)</Label>
              <div className="custom-file-upload-box-gallery mx-auto d-inline-block me-md-auto">
                {isComponentLoading ? (
                  <Spinner size="sm" />
                ) : (
                  <Label className="custom-file-upload">
                    <Input
                      type="file"
                      className="d-none"
                      onChange={(event) => _handleImageUplaod(event)}
                      disabled={isComponentLoading}
                    />
                    Add
                  </Label>
                )}
              </div>
            </FormGroup>
          </Col>
          <Col md="6">
            <div className="uploadedSelfie">
              <img
                src={
                  visitState?.selfieImage !== ""
                    ? visitState?.selfieImage
                    : Default
                }
                alt="Selfie"
              />
            </div>
          </Col>
          <Col sm="12">
            {visitState?.name !== "" && visitState?.selfieImage !== "" ? (
              !isStepOneComplete ? (
                <Button
                  className="addBtn mx-auto d-block"
                  style={{marginTop: 30}}
                  onClick={() =>
                    visitState?.selfieImage !== "" && visitState?.name !== ""
                      ? _handleUpdateStepOneStatus(true)
                      : showToaster("error", "Required field(s) missing")
                  }
                  disabled={isComponentLoading}
                >
                  {isComponentLoading ? <Spinner size="sm" /> : "Save & Next"}
                </Button>
              ) : null
            ) : null}
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
};

export default VisitStepOne;
