import React, { useState } from "react";
import { Col, Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import MultiStepForm from "../components/MutiStepForm";

const VisitStepTwo = ({ visitState, _onUpdate }) => {
  const [activeTab, setActiveTab] = useState(1);

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <React.Fragment>
      <Col sm={12}>
        <div className="visitStepWrap px-3">
          <Nav pills className="customTabs">
            {visitState?.brandsData?.length
              ? visitState?.brandsData?.map((eachBrand, eachIndex) => (
                  <NavItem key={eachIndex}>
                    <NavLink
                      className={classnames({
                        active: activeTab === eachIndex + 1,
                      })}
                      onClick={() => {
                        toggle(eachIndex + 1);
                      }}
                    >
                      {eachBrand?.brandName || `Brand ${eachIndex + 1}`}
                    </NavLink>
                  </NavItem>
                ))
              : "No Brand(s)"}
          </Nav>
          <TabContent activeTab={activeTab} className="customTabContent mb-0">
            {visitState?.brandsData?.length
              ? visitState?.brandsData?.map((eachBrand, eachIndex) => (
                  <TabPane tabId={eachIndex + 1} key={eachIndex}>
                    <MultiStepForm
                      visitState={visitState}
                      brandIndex={eachIndex}
                      _onUpdate={_onUpdate}
                      switchTab={toggle}
                    />
                  </TabPane>
                ))
              : "No Tab(s)"}
          </TabContent>

        </div>
      </Col>
    </React.Fragment>
  );
};

export default VisitStepTwo;
